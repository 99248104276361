import {
  BrandBattlesIcon,
  PerformanceIcon,
  RangeReviewIcon,
  TrendIcon
} from "components/Icons";
import { storyEnums } from "constants/enums";

const getServerURL = () => {
  switch (process.env.REACT_APP_DEV_ENV) {
    case "local":
      return { api: "http://127.0.0.1:5000/api/v1" };
    case "dev":
      return { api: "https://api-dev.dijuno.ai/api/v1" };
    case "haleon-dev":
      return { api: "https://api-dev.haleongb.interrodata.com/api/v1" };
    case "drinksinc":
      return { api: "https://api-demo.dijuno.ai:5001/api/v1" };
    case "poc":
      return { api: "https://api.poc.interrodata.com/api/v1" };
    case "qa":
      return { api: "https://api-qa.dijuno.ai/api/v1" };
    case "stg":
      return { api: "https://api-stg.interrodata.com/api/v1" };
    case "prod":
      return { api: "https://api.dijuno.ai/api/v1" };
    case "suntory":
      return { api: "https://api-suntory.interrodata.com/api/v1" };
    case "upfield":
      return { api: "https://api-upfield.dijuno.ai/api/v1" };
    case "haleon-prod":
      return { api: "https://haleongb.interrodata.com/api/v1" };
    default:
      return { api: "https://api-dev.dijuno.ai/api/v1" };
  }
};

export const getStoryConfig = story => {
  switch (story && story.toLowerCase()) {
    case storyEnums.IDA:
      return {
        type: "performance",
        dataSet: "nielsen",
        channel: "ih",
        icon: PerformanceIcon
      };
    case storyEnums.CGA:
      return {
        type: "performance",
        dataSet: "cga",
        channel: "ooh",
        icon: PerformanceIcon
      };
    case storyEnums.PRR:
      return {
        type: "range-review",
        dataSet: "nielsen",
        channel: "ih",
        icon: RangeReviewIcon
      };
    case storyEnums.BB:
      return {
        type: "brand-battles",
        dataSet: "nielsen_secondary",
        channel: "ih",
        icon: BrandBattlesIcon
      };
    case storyEnums.TREND:
      return {
        type: "trend",
        dataSet: "nielsen_secondary",
        channel: "ih",
        icon: TrendIcon
      };
    default:
      return {
        type: "performance",
        dataSet: "nielsen",
        channel: "ih",
        icon: PerformanceIcon
      };
  }
};

export const getChannelConfig = channel => {
  switch (channel) {
    case "ih":
      return { dataSet: "nielsen_secondary", isMonth: false, name: "In-Home" };
    case "ooh":
      return { dataSet: "cga", isMonth: true, name: "Out-of-Home" };
    default:
      return { dataSet: "nielsen_secondary", isMonth: false, name: "In-Home" };
  }
};

const config = {
  serverURL: { ...getServerURL() },
  app: {
    numberSuggestions: 30,
    numberPreferences: 3,
    userListLimit: 15
  },
  hierarchyList: [
    { name: "category", ranking: 4, isFilter: false, label: "Category" },
    { name: "sector", ranking: 0, isFilter: true, label: "Sector" },
    {
      name: "distributor_id",
      ranking: 4,
      isFilter: false,
      label: "Distributor"
    },
    { name: "major_brand", ranking: 3, isFilter: true, label: "Major Brand" },
    { name: "sub_brand", ranking: 2, isFilter: true, label: "Sub Brand" },
    {
      name: "sugar_content",
      ranking: 1,
      isFilter: false,
      label: "Sugar Content"
    },
    { name: "flavour", ranking: 1, isFilter: false, label: "Flavour" },
    { name: "pack_type", ranking: 1, isFilter: false, label: "Pack Type" }
  ],
  groupings: ["product", "packaging", "package detail"],
  bbProducts: ["distributor_id", "major_brand", "sub_brand"],
  bbComparisonProducts: {
    distributor_id: ["distributor_id"],
    major_brand: ["major_brand", "sub_brand"],
    sub_brand: ["sub_brand", "major_brand"]
  },
  restatementYears: [2020, 2026, 2032, 2037, 2043, 2048],
  isRestatement: false,
  sterlingHighlightColumns: [
    "value_sales",
    "numeric_distribution",
    "ros",
    "financial_score",
    "retained_value",
    "sku_growth"
  ],
  autoRangingDefaults: { numBay: 6, distThreshold: 0.1 }
};

export const dataSetConfig = {
  nielsen: "Nielsen Scantrack",
  kantar: "Kantar Worldpanel",
  cga: "CGA",
  nielsen_mono: "Nielsen Scantrack",
  cga_sd: "CGA"
};

export const dataSetStoryMapping = {
  nielsen_mono: ["ida", "prr", "bb", "trend", "lookout-ih"],
  kantar: ["ida", "prr", "bb", "trend", "lookout-ih"],
  cga_sd: ["cga", "lookout-ooh"]
};

export const CHART_COLORS = [
  "#4472C4",
  "#ED7D31",
  "#A5A5A5",
  "#FFC000",
  "#5B9BD5",
  "#70AD47",
  "#264478",
  "#9E480E",
  "#636363",
  "#997300"
];

export const ALIGNMENT = {
  title: "left",
  section: "left",
  footer: "right",
  width: 0.6
};

export const clientConfig = {
  ccep: {
    name: "CCEP",
    color: "#240000",
    titleColor: "#240000",
    sectionColor: "#240000",
    fontFamily: "arial",
    fontSize: 16,
    chartColors: [
      "#F40009",
      "#00E6A0",
      "#FFD700",
      "#463CC8",
      "#FF9BFA",
      "#878787",
      "#920005",
      "#008A60",
      "#998100",
      "#29227A"
    ],
    alignment: {
      title: "center",
      section: "right",
      footer: "center",
      width: 0.75
    },
    staticDataSets: ["nielsen", "cga", "nielsen_secondary"],
    autoRangingStaticDataSets: ["nielsen_auto_ranging"],
    dataSets: ["nielsen", "kantar", "cga"],
    stories: ["ida", "cga", "bb", "prr", "focus"],
    permissions: ["lookout", "sterling", "auto_ranging", "streamlit"],
    channels: ["ih", "ooh"]
  },
  drinksinc: {
    name: "Drinks Inc",
    staticDataSets: ["nielsen", "nielsen_secondary"],
    autoRangingStaticDataSets: ["nielsen_auto_ranging"],
    dataSets: ["nielsen", "kantar"],
    stories: ["ida", "bb", "focus"],
    permissions: ["lookout", "sterling", "auto_ranging", "streamlit"],
    channels: ["ih"]
  },
  haleon: {
    name: "Haleon",
    color: "#30EA03",
    titleColor: "#30EA03",
    sectionColor: "#000000",
    fontFamily: "verdana",
    fontSize: 14,
    chartColors: [
      "#30EA03",
      "#8D979D",
      "#333F48",
      "#46FFE6",
      "#FF28FF",
      "#DCFF00",
      "#1D8C02",
      "#535B60",
      "#1F262B",
      "#00C3A9"
    ],
    staticDataSets: ["nielsen"],
    dataSets: ["nielsen"],
    stories: ["ida"],
    permissions: ["lookout"],
    channels: ["ih"]
  },
  suntory: {
    name: "Suntory",
    color: "#5BC2DC",
    titleColor: "#FFFFFF",
    sectionColor: "#5BC2DC",
    fontFamily: "roboto",
    fontSize: 16,
    chartColors: [
      "#5BC2DC",
      "#058FB0",
      "#03BDBD",
      "#0099E5",
      "#17E8FF",
      "#BCACD5",
      "#9469AB",
      "#99E599",
      "#43B48F"
    ],
    staticDataSets: ["nielsen"],
    dataSets: ["nielsen"],
    stories: ["ida"],
    permissions: ["lookout"],
    channels: ["ih"]
  },
  upfield: {
    name: "Upfield Inc",
    color: "#0D3B30",
    titleColor: "#FAF5EB",
    sectionColor: "#FAF5EB",
    fontFamily: "arial",
    fontSize: 16,
    chartColors: [
      "#55BD6B",
      "#FDBC40",
      "#FF6F3C",
      "#5DACA7",
      "#9E9E9E",
      "#4E1B3B",
      "#2E763D",
      "#BC7C02",
      "#BD3100",
      "#366966"
    ],
    alignment: {
      title: "left",
      section: "right",
      footer: "center",
      width: 0.48
    },
    staticDataSets: ["nielsen", "nielsen_secondary"],
    dataSets: ["nielsen", "kantar"],
    stories: ["ida", "bb", "focus"],
    permissions: ["lookout", "streamlit"],
    channels: ["ih"]
  }
};

// a list of clients with custom report templates
export const clientTemplates = [
  "ccep",
  "drinksinc",
  "haleon",
  "suntory",
  "upfield"
];

export const lookoutExamples = {
  ccep: {
    ih: [
      {
        request: "CCEP Flavours",
        product: "CCEP",
        scope: "Flavours",
        market: "Total Tesco"
      },
      {
        request:
          "Coca-Cola Zero Multipack cans are performing vs the competition (other Low Calorie Colas)",
        product: "Coca-Cola Zero (major brand)",
        scope:
          "FC Can Multi (pack type), then Low Calorie (sugar content), then Colas (sector)",
        market: "Multiple Grocers"
      },
      {
        request:
          "Immediate Consumption Monster is performing vs the competition (other IC Energy brands)",
        product: "Monster (major brand)",
        scope:
          "Total Energy (category), then Immediate Consumption (consumption type)",
        market: "Sainsbury's Local"
      }
    ],
    ooh: [
      {
        request: "CCEP Mixers",
        product: "CCEP",
        scope: "Mixers",
        market: "On Premise"
      },
      {
        request:
          "Coca-Cola Zero is performing vs the competition (other Low Calorie Colas)",
        product: "Coca-Cola Zero (major brand)",
        scope: "Low Calorie (sugar content), then Colas (sector)",
        market: "London"
      },
      {
        request:
          "Immediate Consumption Monster is performing vs the competition (other IC Energy brands)",
        product: "Monster (major brand)",
        scope:
          "Total Energy (category), then Immediate Consumption (consumption type)",
        market: "Education & Workplace"
      }
    ]
  },
  haleon: {
    ih: [
      {
        request: "Haleon Toothpaste",
        product: "Haleon (distributor)",
        scope: "Toothpaste",
        market: "Grocery Multiples"
      },
      {
        request:
          "Parodontax is performing vs the competition (other Toothpastes)",
        product: "Parodontax (major brand)",
        scope: "Total Toothpaste",
        market: "Boots"
      },
      {
        request:
          "Sensodyne Sensitive is performing vs the competition (other Sensitive Toothpaste brands)",
        product: "Sensodyne",
        scope: "Total Toothpaste (category), then Sensitive (sub sector)",
        market: "Convenience Stores"
      }
    ]
  }
};

export default config;

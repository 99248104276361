import React from "react";
import PropTypes from "prop-types";
import { Link, generatePath } from "react-router-dom";
import { Button, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { EditIcon, RefreshIcon, StarIcon } from "components/Icons";
import { EDIT_AUTO_RANGING_INITIAL_ROUTE } from "constants/viewRoutes";
import { Paper } from "components/BaseComponents";

const Header = props => {
  const {
    report,
    reportId,
    onRefreshData,
    userTracking,
    onAddFavourite,
    isLoadingFavourite
  } = props;
  const { header = "" } = report || {};
  const { isLoading = false, error = "" } = userTracking;
  return (
    <Paper>
      <Stack direction="row" justifyContent="space-between" sx={{ mb: 0.5 }}>
        <Typography variant="h4" color="primary.main">
          Auto-Ranging
        </Typography>
        <Stack direction="row" justifyContent="flex-end" spacing={1}>
          <LoadingButton
            size="small"
            color="disabled"
            loadingPosition="start"
            loading={isLoadingFavourite}
            startIcon={<StarIcon />}
            onClick={onAddFavourite}
            disabled={isLoading || !!error}
            sx={{ color: "grey.500" }}
          >
            Add to favourites
          </LoadingButton>
          <Button
            size="small"
            variant="outlined"
            startIcon={<RefreshIcon />}
            onClick={onRefreshData}
          >
            Refresh with latest data
          </Button>
          <Button
            size="small"
            variant="outlined"
            disabled={isLoading || !!error}
            startIcon={<EditIcon />}
            component={Link}
            to={generatePath(EDIT_AUTO_RANGING_INITIAL_ROUTE, { id: reportId })}
            rel="noreferrer"
            target="_blank"
          >
            Edit Constraints
          </Button>
        </Stack>
      </Stack>
      <Typography variant="subtitle1" fontWeight="medium" color="grey.700">
        {header}
      </Typography>
    </Paper>
  );
};

Header.propTypes = {
  report: PropTypes.shape(),
  reportId: PropTypes.string,
  onRefreshData: PropTypes.func,
  onAddFavourite: PropTypes.func,
  userTracking: PropTypes.shape(),
  isLoadingFavourite: PropTypes.bool
};

Header.defaultProps = {
  report: {},
  reportId: "",
  onRefreshData: () => {},
  onAddFavourite: () => {},
  userTracking: {},
  isLoadingFavourite: false
};

export default Header;

import {
  parse,
  format,
  startOfWeek,
  startOfMonth,
  startOfYear,
  startOfWeekYear,
  endOfWeek,
  endOfMonth,
  endOfYear,
  subDays,
  subWeeks,
  subMonths,
  addDays,
  addWeeks,
  addMonths,
  getWeek,
  getMonth,
  getYear,
  setDay,
  differenceInWeeks,
  differenceInMonths,
  isValid
} from "date-fns";
import _ from "lodash";
import { enGB } from "date-fns/locale";
import config from "config";

const { isRestatement, restatementYears } = config;
const DATE_FORMAT = "yyyy-MM-dd HH:mm:ss";

/**
 * Date options: allow 53 weeks, week starts Sunday
 */
const options = {
  weekStartsOn: 0,
  firstWeekContainsDate: 4,
  useAdditionalWeekYearTokens: true
};

/**
 * Custom locale for datepicker, allows for 53 weeks, week starts Sunday
 */
export const locale = { ...enGB, options };

/**
 * Parse allowing for multiple date formats
 * Based on https://gist.github.com/krutoo/c88dc9259e0ff531f3a640d5c3c6f267
 */
const parseMultiple = (
  dateString,
  formatStrings,
  referenceDate,
  parseOptions
) => {
  let result;
  for (let i = 0; i < formatStrings.length; i += 1) {
    result = parse(dateString, formatStrings[i], referenceDate, parseOptions);
    if (isValid(result)) {
      break;
    }
  }
  return result;
};

/**
 * Format the datepicker week number
 * @param  {Object} date    Date
 */
export const formatWeekNumber = date => {
  const year = getYear(date);
  if (isRestatement) {
    return getWeek(date, options);
  }
  const pastRestatementYears = restatementYears.filter(i => i <= year);
  // after restatement
  if (
    _.includes(pastRestatementYears, year) ||
    _.includes(pastRestatementYears, year + 1) ||
    _.includes(pastRestatementYears, year + 2)
  ) {
    const weekNumber = getWeek(date, options) - 1;
    return weekNumber === 0 ? 52 : weekNumber;
  }
  const weekNumber = getWeek(date, options);
  // after restatement - no 53 week year
  return weekNumber === 53 ? 52 : weekNumber;
};

/**
 * Get the date of the start i.e. Sunday of a given week
 * @param  {Object} date    Date in format ddMMyy or YYYYww or yyyy-MM-dd HH:mm:ss- where ww is the week number (1-53)
 */
export const getWeekStart = date =>
  startOfWeek(
    parseMultiple(date, ["ddMMyy", "YYYYww", DATE_FORMAT], new Date(), options),
    options
  );

/**
 * Get the date of the end i.e. Saturday of a given week
 * @param  {Object} date    Date in format ddMMyy or YYYYww or yyyy-MM-dd HH:mm:ss - where ww is the week number (1-53)
 */
export const getWeekEnd = date =>
  endOfWeek(
    parseMultiple(date, ["ddMMyy", "YYYYww", DATE_FORMAT], new Date(), options),
    options
  );

/**
 * Get the date of the start of a given month
 * @param  {Object} date    Date in format MMMYY
 */
export const getMonthStart = date =>
  startOfMonth(parseMultiple(date, ["MMMyy", DATE_FORMAT], new Date()));

/**
 * Get the date of the end of a given month
 * @param  {Object} date    Date in format MMMYY
 */
export const getMonthEnd = date =>
  endOfMonth(parseMultiple(date, ["MMMyy", DATE_FORMAT], new Date()));

/**
 * Get the comparison period dates from the initial period dates
 * @param  {Object} periodOption    Option for comparison period - yearAgo, justBefore or Custom
 * @param  {Object} start           Date for the start of the initial period
 * @param  {Object} end             Date for the end of the initial period
 * @param  {Boolean} isMonth        Is date in month format
 * @param  {Object} customStart     Date for the start of the custom compare period - optional
 * @param  {Object} customEnd       Date for the end of the custom compare period - optional
 * @return {Array}                  Array containing the comparison start date and comparison end date
 */
export const getCompareDates = (
  periodOption,
  start,
  end,
  isMonth,
  customStart,
  customEnd
) => {
  let compareStart;
  let compareEnd;
  if (periodOption === "yearAgo") {
    compareStart = isMonth ? subMonths(start, 12) : subWeeks(start, 52);
    compareEnd = isMonth ? subMonths(end, 12) : subWeeks(end, 52);
  } else if (periodOption === "justBefore") {
    const diff = isMonth
      ? differenceInMonths(end, start) + 1
      : differenceInWeeks(end, start) + 1;
    compareStart = isMonth ? subMonths(start, diff) : subWeeks(start, diff);
    compareEnd = isMonth ? subMonths(end, diff) : subWeeks(end, diff);
  } else if (periodOption === "custom") {
    compareStart = customStart;
    compareEnd = customEnd;
  }
  return [compareStart, compareEnd];
};

/**
 * Convert custom dates to correct format for the backend
 * @param  {Object}   start           Date for the start of the initial period
 * @param  {Object}   end             Date for the end of the initial period
 * @param  {Object}   compareStart    Date for the start of the compare period
 * @param  {Object}   compareEnd      Date for the end of the compare period
 * @param  {Boolean}  isMonth         Is date in month format
 * @return {String}                   Comma separated string of 4 dates chronologically in format yyyy-MM-dd HH:mm:ss
 */
export const getDatePeriod = (start, end, compareStart, compareEnd, isMonth) =>
  isMonth
    ? `${format(startOfMonth(compareStart), DATE_FORMAT)},${format(
        endOfMonth(compareEnd),
        DATE_FORMAT
      )},${format(startOfMonth(start), DATE_FORMAT)},${format(
        endOfMonth(end),
        DATE_FORMAT
      )}`
    : `${format(
        startOfWeek(compareStart, options),
        DATE_FORMAT,
        options
      )},${format(
        endOfWeek(compareEnd, options),
        DATE_FORMAT,
        options
      )},${format(startOfWeek(start, options), DATE_FORMAT, options)},${format(
        endOfWeek(end, options),
        DATE_FORMAT,
        options
      )}`;

/**
 * Convert the implementation date to correct format for backend
 * @param  {String} selectedValue     The implementation date
 * @return {String}                   Date in yyyy-MM-dd HH:mm:ss format
 */
export const getImplementationPeriod = selectedValue =>
  format(startOfWeek(selectedValue, options), DATE_FORMAT, options);

/**
 * Helper function for getUpdateDate and getLookoutPeriod to calculate current period (1-13)
 */
const getCurrentPeriod = date => getWeek(date, options) / 4;

/**
 * Calculate next data update date and period from data date
 * @param  {String} dataDate          The current data date
 * @param  {Boolean} isMonth          Is date in month format
 * @return {String}                   Date in PXX: dd/MM/yy format where XX is period number (1-13) or (1-12)
 */
export const getUpdateDate = (dataDate, isMonth = false) => {
  const date = dataDate ? parse(dataDate, "dd/MM/yy", new Date()) : new Date();
  const updateDate = isMonth
    ? addMonths(date, 3)
    : subDays(addWeeks(date, 6), 1);
  const formattedDate = format(updateDate, "dd/MM/yy", options);
  const periodNumber = isMonth
    ? ((getMonth(date) + 1) % 12) + 1
    : (getCurrentPeriod(date) % 13) + 1;
  return `P${periodNumber}: ${formattedDate}`;
};

/**
 * Calculate current lookout period from data date
 * @param  {String} dataDate          The current data date
 * @param  {Boolean} isMonth          Is date in month format
 * @return {String}                   Period in PXX: YYYY format where XX is period number (1-13) or (1-12)
 */
export const getLookoutPeriod = (dataDate, isMonth = false) => {
  const date = dataDate ? parse(dataDate, "dd/MM/yy", new Date()) : new Date();
  const startDate = addDays(subWeeks(date, 4), 4);
  return `P${isMonth ? getMonth(date) + 1 : getCurrentPeriod(date)}: ${getYear(
    startDate
  )}`;
};

/**
 * Calculate current lookout period from data date
 * @param  {String} dataDate          The current data date
 * @param  {Boolean} isMonth          Is date in month format
 * @return {String}                   Date range in dd/MM/yy - dd/MM/yy format or MMM yy format
 */
export const getLookoutDate = (dataDate, isMonth = false) => {
  if (isMonth) {
    const date = dataDate
      ? parse(dataDate, "dd/MM/yyyy", new Date())
      : new Date();
    return format(date, "MMM yy", options);
  }
  const date = dataDate ? parse(dataDate, "dd/MM/yy", new Date()) : new Date();
  const startDate = addDays(subWeeks(date, 4), 1);
  const formattedStartDate = format(startDate, "dd/MM/yy", options);
  return `${formattedStartDate} - ${dataDate}`;
};

/**
 * Convert date periods to the exact dates
 * @param  {String} period        Name of period used
 * @param  {Object} dataDate      The last date in the data
 * @param  {Boolean} isMonth      Is date in month format
 * @return {Array}                Array of the start and end date of initial and compare period
 */
export const getDateFromPeriod = (period, dataDate, isMonth) => {
  const lastValidDate = dataDate
    ? parse(dataDate, "dd/MM/yy", new Date())
    : new Date();
  let start;
  let end;
  let compareStart;
  let compareEnd;
  switch (period) {
    case "Year to Date":
      start = isMonth
        ? startOfYear(lastValidDate)
        : startOfWeekYear(lastValidDate, options);
      end = lastValidDate;
      compareStart = isMonth ? subMonths(start, 12) : subWeeks(start, 52);
      compareEnd = isMonth ? subMonths(end, 12) : subWeeks(end, 52);
      return [compareStart, compareEnd, start, end];
    case "Latest 52 Weeks":
      start = setDay(subWeeks(lastValidDate, 52), 7);
      end = lastValidDate;
      compareStart = subWeeks(start, 52);
      compareEnd = subWeeks(end, 52);
      return [compareStart, compareEnd, start, end];
    case "Latest 24 Weeks":
      start = setDay(subWeeks(lastValidDate, 24), 7);
      end = lastValidDate;
      compareStart = subWeeks(start, 52);
      compareEnd = subWeeks(end, 52);
      return [compareStart, compareEnd, start, end];
    case "Latest 12 Weeks":
      start = setDay(subWeeks(lastValidDate, 12), 7);
      end = lastValidDate;
      compareStart = subWeeks(start, 52);
      compareEnd = subWeeks(end, 52);
      return [compareStart, compareEnd, start, end];
    case "Latest 4 Weeks":
      start = setDay(subWeeks(lastValidDate, 4), 7);
      end = lastValidDate;
      compareStart = subWeeks(start, 52);
      compareEnd = subWeeks(end, 52);
      return [compareStart, compareEnd, start, end];
    case "Latest Month":
      start = startOfMonth(lastValidDate);
      end = lastValidDate;
      compareStart = subMonths(start, 12);
      compareEnd = subMonths(end, 12);
      return [compareStart, compareEnd, start, end];
    case "Latest 3 Months":
      start = startOfMonth(subMonths(lastValidDate, 2));
      end = lastValidDate;
      compareStart = subMonths(start, 12);
      compareEnd = subMonths(end, 12);
      return [compareStart, compareEnd, start, end];
    case "Latest 6 Months":
      start = startOfMonth(subMonths(lastValidDate, 5));
      end = lastValidDate;
      compareStart = subMonths(start, 12);
      compareEnd = subMonths(end, 12);
      return [compareStart, compareEnd, start, end];
    case "Latest 12 Months":
      start = startOfMonth(subMonths(lastValidDate, 11));
      end = lastValidDate;
      compareStart = subMonths(start, 12);
      compareEnd = subMonths(end, 12);
      return [compareStart, compareEnd, start, end];
    default:
      // year
      start = isMonth
        ? startOfYear(parse(period, "yyyy", new Date()))
        : (((!isRestatement &&
            (_.includes(restatementYears, _.toNumber(period)) ||
              _.includes(restatementYears, _.toNumber(period) + 1))) ||
            _.includes(restatementYears, _.toNumber(period) + 2)) &&
            addWeeks(startOfWeekYear(new Date(period, 0, 4), options), 1)) ||
          startOfWeekYear(new Date(period, 0, 4), options);
      end = isMonth
        ? endOfYear(parse(period, "yyyy", new Date()))
        : setDay(
            addWeeks(
              start,
              isRestatement && _.includes(restatementYears, _.toNumber(period))
                ? 53
                : 52
            ),
            -1
          );
      compareStart = isMonth ? subMonths(start, 12) : subWeeks(start, 52);
      compareEnd = isMonth
        ? subMonths(end, 12)
        : subWeeks(
            end,
            isRestatement && _.includes(restatementYears, _.toNumber(period))
              ? 53
              : 52
          );
      return [compareStart, compareEnd, start, end];
  }
};

export const getImplementationDateLabel = dateString =>
  format(new Date(getWeekStart(dateString)), "dd/MM/yyyy");

/**
 * Convert date periods to readable string for search input bar
 * @param  {String} dateString    Comma separated string of 4 dates chronologically in format YYYYWW or MMMYY
 * @return {String}               Human readable date period
 */
export const getDateLabel = (dateString, story) => {
  const isMonth = story === "cga";
  const dates = dateString.split(",").map((i, k) => {
    if (isMonth) {
      return k % 2 === 0 ? getMonthStart(i) : getMonthEnd(i);
    }
    return k % 2 === 0 ? getWeekStart(i) : getWeekEnd(i);
  });
  if (story === "prr") {
    return `Post Review Period (${format(dates[2], "dd/MM/yyyy")} - ${format(
      dates[3],
      "dd/MM/yyyy"
    )}) Vs Comparison Period (${format(dates[0], "dd/MM/yyyy")} - ${format(
      dates[1],
      "dd/MM/yyyy"
    )})`;
  }
  return `Custom period: (${format(
    dates[2],
    isMonth ? "MMMM yyyy" : "dd/MM/yyyy"
  )} - ${format(dates[3], isMonth ? "MMMM yyyy" : "dd/MM/yyyy")} Vs ${format(
    dates[0],
    isMonth ? "MMMM yyyy" : "dd/MM/yyyy"
  )} - ${format(dates[1], isMonth ? "MMMM yyyy" : "dd/MM/yyyy")})`;
};

import pptxgen from "pptxgenjs";
import _ from "lodash";
import { ALIGNMENT, CHART_COLORS, clientConfig } from "config";
import theme from "theme";
import { getTextAlign, getUniqueColors, replaceText, wrapText } from "utils";
import {
  CONTENT_DETAILS,
  KANTAR_COLORS,
  IMPACT_COLORS,
  IMPACT_ITEMS
} from "constants/reportConstants";
import {
  addTemplates,
  getChartLayout,
  getTableRows,
  getTableInfo,
  getBulletArray,
  getMinMax,
  getChartDetails,
  addApplySubchart,
  addMaximumValue,
  getColor,
  getContentArray
} from "./pptHelperFunctions";

export const POSITIVE = "01B050";
export const NEGATIVE = "FF0000";

const WATERFALL_COLORS = {
  red: "FF0000",
  green: "228B22",
  black: "000000",
  white: "transparent"
};

export const getSlideTemplate = formatKey => {
  if (formatKey === "title") return "TITLE_SLIDE";
  if (formatKey === "section") return "SECTION_SLIDE";
  return "MASTER_SLIDE";
};

export const addClusteredColumn = (
  ppt,
  slide,
  chart,
  layout,
  colors,
  color
) => {
  const {
    apply,
    labels_style: labelStyle,
    chart_style: chartStyle,
    values_style: valueStyle
  } = chart.style;
  const { minimum_scale: min, maximum_scale: max } = valueStyle;
  const { title } = chart;
  const isValence = apply?.includes("valence");
  const isSubchart = apply?.includes("subchart");
  const isSeries = apply?.includes("series");
  const isAligned = chartStyle.is_aligned;
  const numberFormat = labelStyle.number_format;
  const chartData = [{ labels: chart.x, values: chart.y }];
  slide.addChart(ppt.ChartType.bar, chartData, {
    ...getChartLayout(layout),
    barGapWidthPct: isSubchart ? 20 : 50,
    catAxisHidden: false,
    catAxisLabelFontSize: isSubchart ? 10 : 12,
    catAxisLabelPos: isAligned && "none",
    valAxisHidden: true,
    valAxisMinVal: isSubchart ? min || Math.min(...chart.y, 0) : min,
    valAxisMaxVal: max,
    valGridLine: { style: "none" },
    showValue: true,
    showTitle: !isSubchart && !!title,
    title,
    titleColor: theme.palette.grey[500],
    titleFontSize: 14,
    dataLabelFormatCode: numberFormat,
    dataLabelPosition: isValence ? "outEnd" : "inEnd",
    dataLabelFontSize: (isSubchart && 8) || 14,
    dataLabelColor: !isValence && "FFFFFF",
    chartColors: (isValence && [POSITIVE]) || (isSeries && colors) || [color],
    invertedColors: (isValence && [NEGATIVE]) || (isSeries && colors) || [color]
  });
};

export const addClusteredBar = (ppt, slide, chart, layout, colors) => {
  const { apply, labels_style: labelStyle } = chart.style;
  const { title, x_axis_title: xAxisTitle } = chart;
  const isValence = apply?.includes("valence");
  const isSubchart = apply?.includes("subchart");
  const isSecond = isSubchart && chart.isSecond;
  const numberFormat = labelStyle.number_format;
  const chartData = [{ labels: chart.x, values: chart.y }];
  slide.addChart(ppt.ChartType.bar, chartData, {
    ...getChartLayout(layout),
    barDir: "bar",
    barGapWidthPct: 50,
    catAxisHidden: isSecond,
    catAxisLabelFontSize: isSubchart ? 8 : 12,
    catAxisLabelPos: isSubchart && "low",
    valAxisHidden: true,
    valGridLine: { style: "none" },
    showValue: true,
    showTitle: !isSubchart && !!title,
    showValAxisTitle: (isSubchart && !!title) || !!xAxisTitle,
    title,
    valAxisTitle: title || xAxisTitle,
    titleColor: theme.palette.grey[500],
    valAxisTitleColor: theme.palette.grey[500],
    titleFontSize: 14,
    valAxisTitleFontSize: 10,
    dataLabelFormatCode: numberFormat,
    dataLabelPosition: isValence ? "outEnd" : "inEnd",
    dataLabelFontSize: isSubchart ? 8 : 14,
    dataLabelColor: !isValence && "FFFFFF",
    chartColors: (isValence && [POSITIVE]) || colors,
    invertedColors: (isValence && [NEGATIVE]) || colors
  });
};

export const addStackedColumn = (ppt, slide, chart, layout, colors) => {
  const {
    apply,
    labels_style: labelStyle,
    chart_style: chartStyle,
    values_style: valueStyle
  } = chart.style;
  const { minimum_scale: min, maximum_scale: max } = valueStyle;
  const { title } = chart;
  const isWaterfall = apply?.includes("waterfall");
  const isAligned = chartStyle.is_aligned;
  const numberFormat = labelStyle.number_format;
  const isColorMap = !_.isEmpty(chartStyle.color_map);
  const colorMap = chartStyle.color_map;
  const uniqueColors = getUniqueColors(colors, colorMap, true);
  const chartData = chart.y.map(i => ({
    labels: chart.x,
    values: i[1],
    name: i[0]
  }));
  const isLabelMap = !_.isEmpty(labelStyle.individual_map);
  slide.addChart(ppt.ChartType.bar, chartData, {
    ...getChartLayout(layout),
    barGrouping: "stacked",
    barGapWidthPct: 50,
    showLegend: !isWaterfall,
    legendFontSize: 12,
    legendPos: !isLabelMap && "b",
    catAxisHidden: isAligned,
    catAxisLabelFontSize: 12,
    valAxisHidden: isLabelMap || isWaterfall,
    valAxisLabelFormatCode: numberFormat,
    valGridLine: !isWaterfall && isLabelMap && { style: "none" },
    valAxisMinVal: min,
    valAxisMaxVal: max,
    showValue: !isWaterfall && isLabelMap,
    showTitle: !!title,
    title,
    titleColor: theme.palette.grey[500],
    titleFontSize: 14,
    dataLabelFormatCode: numberFormat,
    dataLabelFontSize: 10,
    dataLabelColor: "FFFFFF",
    chartColors:
      (isWaterfall && chart.y.map(i => WATERFALL_COLORS[i[0]])) ||
      (isColorMap
        ? chart.y.map((i, k) => colorMap[k] || uniqueColors[k])
        : colors),
    invertedColors:
      (isWaterfall && chart.y.map(i => WATERFALL_COLORS[i[0]])) || colors
  });
};

export const addStackedBar = (ppt, slide, chart, layout, colors) => {
  const {
    apply,
    labels_style: labelStyle,
    chart_style: chartStyle,
    values_style: valueStyle
  } = chart.style;
  const { minimum_scale: min, maximum_scale: max } = valueStyle;
  const numberFormat = labelStyle.number_format;
  const isColorMap = !_.isEmpty(chartStyle.color_map);
  const colorMap = chartStyle.color_map;
  const uniqueColors = getUniqueColors(colors, colorMap, true);
  const chartData = chart.y.map(i => ({
    labels: chart.x.map(j => wrapText(j, 15, "\n")),
    values: i[1],
    name: i[0]
  }));
  const isKantar = apply?.includes("kantar");
  const isImpact = apply?.includes("impact");
  slide.addChart(ppt.ChartType.bar, chartData, {
    ...getChartLayout(layout),
    barDir: "bar",
    barGrouping: "stacked",
    barGapWidthPct: 50,
    showLegend: !isImpact,
    legendFontSize: 12,
    legendPos: "b",
    catAxisLabelFontSize: 12,
    catAxisLabelPos: (isKantar || isImpact) && "low",
    valAxisHidden: true,
    valGridLine: { style: "none" },
    valAxisMinVal: min,
    valAxisMaxVal: max,
    showValue: !isKantar,
    dataLabelFormatCode: numberFormat,
    dataLabelFontSize: (isImpact && 7) || 10,
    dataLabelColor: "FFFFFF",
    chartColors:
      (isColorMap && chart.y.map((i, k) => colorMap[k] || uniqueColors[k])) ||
      (isImpact && chart.y.map(i => IMPACT_COLORS[i[0]])) ||
      colors,
    invertedColors:
      (isColorMap && chart.y.map((i, k) => colorMap[k] || uniqueColors[k])) ||
      (isImpact && chart.y.map(i => IMPACT_COLORS[i[0]])) ||
      colors
  });
};

export const addPieChart = (ppt, slide, chart, layout, colors) => {
  const { title } = chart;
  slide.addChart(ppt.ChartType.pie, [{ labels: chart.x, values: chart.y }], {
    ...getChartLayout(layout),
    showLabel: true,
    showPercent: true,
    chartColors: colors,
    dataLabelPosition: "ctr",
    dataLabelFontSize: 8,
    dataLabelColor: "FFFFFF",
    showTitle: !!title,
    title,
    titleColor: theme.palette.grey[500],
    titleFontSize: 14
  });
};

const addMultiChart = (ppt, slide, charts, layout, colors) => {
  const allValues = _.flattenDeep(charts.map(chart => chart.y.map(i => i[1])));
  const isScaled = _.min(allValues) < 0;
  const comboTypes = charts.map((chart, index) => {
    const chartData = chart.y.map(i => ({
      name: i[0],
      labels: chart.x,
      values: i[1]
    }));
    const { type, color_map: colorMap } = chart.style.chart_style;
    return {
      ...getChartDetails(ppt, type, colorMap, chart.y, index, colors),
      data: chartData
    };
  });
  slide.addChart(comboTypes, {
    ...getChartLayout(layout),
    showLegend: true,
    legendPos: "t",
    legendFontSize: 10,
    valAxes: charts.map((chart, k) => {
      const { type } = chart.style.chart_style;
      const { valAxisMinVal, valAxisMaxVal } = isScaled
        ? getMinMax(chart, type)
        : {};
      const { labels_style: labelStyle } = chart.style;
      const numberFormat = labelStyle.number_format;
      return {
        valAxisLabelFormatCode: numberFormat,
        valAxisLabelFontSize: 12,
        valGridLine: { style: "none" },
        valAxisMinVal,
        valAxisMaxVal,
        showValAxisTitle: true,
        valAxisTitle: chart.y_axis_title,
        valAxisTitleFontSize: 12,
        valAxisTitleRotate: k % 2 === 0 ? 270 : 90
      };
    }),
    catAxes: [{ catAxisLabelFontSize: 12 }, { catAxisHidden: true }]
  });
};

const addChart = (ppt, slide, type, chart, layout, config, color) => {
  const { colors } = config;
  if (type === "clustered_column") {
    addClusteredColumn(ppt, slide, chart, layout, colors, color);
  } else if (type === "clustered_bar") {
    addClusteredBar(ppt, slide, chart, layout, colors);
  } else if (type === "stacked_column") {
    addStackedColumn(ppt, slide, chart, layout, colors);
  } else if (type === "stacked_bar") {
    addStackedBar(ppt, slide, chart, layout, colors);
  } else if (type === "pie") {
    addPieChart(ppt, slide, chart, layout, colors);
  } else if (type === "multi") {
    addMultiChart(ppt, slide, chart, layout, colors);
  }
};

export const addHeading = (slide, heading, fontSize, color) => {
  slide.addText(heading, {
    x: "4%",
    y: "4%",
    w: "82%",
    h: "10%",
    color,
    fontSize: 1.125 * fontSize,
    valign: "top"
  });
};

export const addTitleHeading = (
  slide,
  heading,
  fontSize,
  color,
  date,
  title,
  alignment
) => {
  const headingArray = [];
  if (title) {
    headingArray.push({
      text: title,
      options: {
        fontSize: 1.5 * fontSize,
        bold: true,
        breakLine: true,
        paraSpaceAfter: 12
      }
    });
  }
  headingArray.push({
    text: heading,
    options: { fontSize: 1.5 * fontSize, breakLine: true, paraSpaceAfter: 12 }
  });
  if (date) {
    headingArray.push({ text: date, options: { fontSize: 0.875 * fontSize } });
  }
  let xValue = 4;
  if (alignment === "center") xValue = 27.5;
  if (alignment === "right") xValue = 51;
  slide.addText(headingArray, {
    x: `${xValue}%`,
    y: "25%",
    w: "45%",
    h: "50%",
    color,
    align: getTextAlign(alignment),
    valign: "top"
  });
};

export const addSectionHeading = (
  slide,
  heading,
  fontSize,
  color,
  alignment
) => {
  const sectionWidth = 100 * alignment.width - 4;
  let xValue = 4;
  if (alignment.section === "center") xValue = 50 - sectionWidth / 2;
  if (alignment.section === "right") xValue = 100 - sectionWidth - 4;
  slide.addText(heading, {
    x: `${xValue}%`,
    y: "9%",
    w: `${sectionWidth}%`,
    h: "10%",
    color,
    fontSize: 1.5 * fontSize,
    align: getTextAlign(alignment.section),
    valign: "top"
  });
};

export const addFooter = (slide, footer, fontSize, alignment) => {
  const combinedFooter = footer.join("     |     ");
  slide.addText(combinedFooter, {
    x: "2%",
    y: "96%",
    w: "96%",
    h: "4%",
    color: theme.palette.grey[500],
    fontSize: 0.625 * fontSize,
    align: alignment,
    valign: "top"
  });
};

export const addTitle = (slide, title, fontSize) => {
  slide.addText(title, {
    x: "4%",
    y: "14%",
    w: "92%",
    h: "8%",
    color: theme.palette.grey[500],
    fontSize: 0.875 * fontSize,
    valign: "top"
  });
};

export const addOverflow = (ppt, slide, overflow, fontSize) => {
  slide.addText(overflow, {
    shape: ppt.ShapeType.roundRect,
    x: "4%",
    y: "85%",
    w: "92%",
    h: "6%",
    color: theme.palette.common.black,
    fontSize: 0.75 * fontSize,
    align: "center",
    valign: "middle",
    fill: theme.palette.primaryArray[50],
    line: { color: theme.palette.primary.main, width: "0.5" }
  });
};

export const addInfoText = (ppt, slide, info, fontSize) => {
  slide.addText(info, {
    shape: ppt.ShapeType.roundRect,
    rectRadius: 0.1,
    x: "73%",
    y: "20%",
    w: "25%",
    h: "6%",
    color: theme.palette.common.black,
    fontSize: 0.75 * fontSize,
    align: "center",
    valign: "middle",
    fill: theme.palette.neutralArray[50],
    line: { color: theme.palette.neutral.main, width: "0.5" }
  });
};

export const addWarning = (slide, info, fontSize, isTitle) => {
  slide.addText(info, {
    x: "4%",
    y: isTitle ? "18%" : "13%",
    w: "92%",
    h: "6%",
    color: theme.palette.negativeArray[400],
    fontSize: 0.875 * fontSize,
    bold: true,
    valign: "top"
  });
};

export const addExtraInfo = (slide, extraInfo, fontSize) => {
  if (Array.isArray(extraInfo)) {
    for (let i = 0; i < extraInfo.length; i += 1) {
      const text = extraInfo[i];
      slide.addText(text, {
        x: "4%",
        y: `${86 + 3.5 * i}%`,
        w: "92%",
        h: "3.5%",
        color: theme.palette.grey[700],
        fontSize: 0.75 * fontSize,
        align: "center"
      });
    }
  } else {
    slide.addText(extraInfo, {
      x: "4%",
      y: "86%",
      w: "92%",
      h: "6%",
      color: theme.palette.grey[700],
      fontSize: 0.875 * fontSize,
      align: "center",
      italic: true
    });
  }
};

const addDualTable = (slide, tables, config) => {
  const { fontSize } = config;
  const leftTable = getTableInfo(tables[0]);
  const rightTable = getTableInfo(tables[1]);
  const leftTitle = leftTable.title;
  const rightTitle = rightTable.title;
  if (leftTitle) {
    slide.addText(leftTitle, {
      x: "4%",
      y: "17%",
      w: "44%",
      h: "8%",
      color: theme.palette.grey[500],
      fontSize: 0.875 * fontSize,
      valign: "top"
    });
  }
  if (rightTitle) {
    slide.addText(rightTitle, {
      x: "52%",
      y: "17%",
      w: "44%",
      h: "8%",
      color: theme.palette.grey[500],
      fontSize: 0.875 * fontSize,
      valign: "top"
    });
  }
  slide.addTable(
    getTableRows(
      leftTable.data,
      leftTable.highlight,
      leftTable.gradient,
      config
    ),
    {
      x: "4%",
      y: "25%",
      w: "44%",
      align: "center",
      valign: "middle"
    }
  );
  slide.addTable(
    getTableRows(
      rightTable.data,
      rightTable.highlight,
      rightTable.gradient,
      config
    ),
    {
      x: "52%",
      y: "25%",
      w: "44%",
      align: "center",
      valign: "middle"
    }
  );
};

const addDualTableChart = (ppt, slide, tables, charts, config) => {
  const table = getTableInfo(tables[0]);
  slide.addTable(
    getTableRows(table.data, table.highlight, table.gradient, config),
    {
      x: "4%",
      y: "23%",
      w: "44%",
      align: "center",
      valign: "middle"
    }
  );
  const chart = charts[0];
  const { type } = chart.style.chart_style;
  const layout = { left: 52, top: 23, width: 44, height: 60 };
  addChart(ppt, slide, type, chart, layout, config);
};

const addChartTable = (ppt, slide, tables, charts, config) => {
  const table = getTableInfo(tables[0]);
  const tableWidth = (table?.data?.columns?.length || 0) + 1;
  slide.addTable(
    getTableRows(table.data, table.highlight, table.gradient, config),
    {
      x: "4%",
      y: "60%",
      w: "92%",
      align: "center",
      valign: "middle"
    }
  );
  const chart = charts[0];
  const { type } = chart.style.chart_style;
  const isAligned = chart?.style?.chart_style?.is_aligned;
  const layout = {
    left: isAligned ? 92 / tableWidth + 4 : 4,
    top: 23,
    width: isAligned ? 92 - 92 / tableWidth : 92,
    height: 35
  };
  addChart(ppt, slide, type, chart, layout, config);
};

const addDualChartTable = (ppt, slide, tables, charts, config) => {
  const leftTable = getTableInfo(tables[0]);
  const rightTable = getTableInfo(tables[1]);
  slide.addTable(
    getTableRows(
      leftTable.data,
      leftTable.highlight,
      leftTable.gradient,
      config
    ),
    {
      x: "4%",
      y: "65%",
      w: "44%",
      align: "center",
      valign: "middle"
    }
  );
  slide.addTable(
    getTableRows(
      rightTable.data,
      rightTable.highlight,
      rightTable.gradient,
      config
    ),
    {
      x: "52%",
      y: "65%",
      w: "44%",
      align: "center",
      valign: "middle"
    }
  );
  const leftChart = charts[0];
  const rightChart = charts[1];
  const leftType = leftChart.style.chart_style.type;
  const rightType = rightChart.style.chart_style.type;
  const rightTableWidth = (rightTable?.data?.columns?.length || 0) + 1;
  const isRightAligned = rightChart?.style?.chart_style?.is_aligned;
  const leftLayout = {
    left: 4,
    top: 23,
    width: 44,
    height: 40
  };
  const rightLayout = {
    left: isRightAligned ? 44 / rightTableWidth + 52 : 52,
    top: 23,
    width: isRightAligned ? 44 - 44 / rightTableWidth : 44,
    height: 40
  };
  addChart(ppt, slide, leftType, leftChart, leftLayout, config);
  addChart(ppt, slide, rightType, rightChart, rightLayout, config);
};

const addMultipleCharts = (ppt, slide, charts, config) => {
  const { colors, fontSize } = config;
  const numCharts = charts.length;
  for (let i = 0; i < numCharts; i += 1) {
    const chart = charts[i];
    const { type } = chart.style.chart_style;
    const value = chart.style?.additional_info?.value;
    const isPositive = value && value.startsWith("+");
    const layout = {
      left: 4 + (92 / numCharts) * i + 1,
      top: 23,
      width: 92 / numCharts - 2,
      height: value ? 50 : 60
    };
    const chartColor = colors[i];
    addChart(ppt, slide, type, chart, layout, config, chartColor);
    if (value) {
      const position = {
        x: `${(92 / numCharts) * i + 46 / numCharts}%`,
        y: "75%"
      };
      slide.addText(value, {
        shape: ppt.ShapeType.roundRect,
        ...position,
        w: "8%",
        h: "5%",
        color: isPositive
          ? theme.palette.positive.main
          : theme.palette.negative.main,
        fontSize: 0.75 * fontSize,
        align: "center",
        valign: "middle",
        fill: isPositive
          ? theme.palette.positive.light
          : theme.palette.negative.light,
        line: {
          color: isPositive
            ? theme.palette.positive.main
            : theme.palette.negative.main
        }
      });
    }
  }
};

const addSummary = (ppt, slide, texts, config) => {
  const { fontSize } = config;
  const context = texts.find(i => i.key === "context");
  const drivers = texts.find(i => i.key === "drivers");
  const restrainers = texts.find(i => i.key === "restrainers");
  const subsetTitle = texts.find(i => i.key === "dynamic_executive_title");
  const winnersTitle = texts.find(i => i.key === "winners_title");
  const winners = texts.find(i => i.key === "winners");
  const losersTitle = texts.find(i => i.key === "losers_title");
  const losers = texts.find(i => i.key === "losers");
  const stars = texts.find(i => i.key === "star_items");
  const under = texts.find(i => i.key === "under_items");
  const performersOrder = texts.find(i => i.key === "sales_direction");
  const isSubset = winners || losers;
  const isSubsetTitle = !!subsetTitle;
  if (context) {
    const textArray = getBulletArray(context.text);
    slide.addText(textArray, {
      x: "4%",
      y: "16%",
      w: "92%",
      h: "42%",
      color: theme.palette.grey[500],
      fontSize: 0.875 * fontSize,
      lineSpacing: 1.25 * fontSize,
      valign: "top"
    });
  }
  if (drivers) {
    const textArray = getBulletArray(drivers.text);
    const title = texts.find(i => i.key === "drivers_title")?.text;
    slide.addText(
      title
        ? [
            {
              text: title,
              options: { color: theme.palette.common.black }
            },
            ...textArray
          ]
        : textArray,
      {
        x: "4%",
        y: "58%",
        w: "92%",
        h: "15%",
        shape: ppt.ShapeType.roundRect,
        rectRadius: 0.1,
        color: theme.palette.grey[500],
        fontSize: 0.875 * fontSize,
        lineSpacing: 1.25 * fontSize,
        valign: "top",
        fill: theme.palette.positive.light,
        line: { color: theme.palette.positive.main, width: "0.5" }
      }
    );
  }
  if (restrainers) {
    const textArray = getBulletArray(restrainers.text);
    const title = texts.find(i => i.key === "restrainers_title")?.text;
    slide.addText(
      title
        ? [
            {
              text: title,
              options: { color: theme.palette.common.black }
            },
            ...textArray
          ]
        : textArray,
      {
        x: "4%",
        y: "76%",
        w: "92%",
        h: "15%",
        shape: ppt.ShapeType.roundRect,
        rectRadius: 0.1,
        color: theme.palette.grey[500],
        fontSize: 0.875 * fontSize,
        lineSpacing: 1.375 * 0.875 * fontSize,
        valign: "top",
        fill: theme.palette.negative.light,
        line: { color: theme.palette.negative.main, width: "0.5" }
      }
    );
  }
  if (performersOrder && performersOrder.text) {
    const isNegative = performersOrder.text === "Neg";
    if (stars) {
      const starsArray = getBulletArray(stars.text);
      const starsTitle = texts.find(i => i.key === "star_title")?.text;
      slide.addText(
        starsTitle
          ? [
              {
                text: starsTitle,
                options: { color: theme.palette.common.black }
              },
              ...starsArray
            ]
          : starsArray,
        {
          x: "4%",
          y: isNegative ? "58%" : "35%",
          w: "92%",
          h: "20%",
          shape: ppt.ShapeType.roundRect,
          rectRadius: 0.1,
          color: theme.palette.grey[500],
          fontSize: 0.875 * fontSize,
          lineSpacing: 1.25 * fontSize,
          valign: "top",
          fill: theme.palette.positive.light,
          line: { color: theme.palette.positive.main, width: "0.5" }
        }
      );
    }
    if (under) {
      const undersArray = getBulletArray(under.text);
      const undersTitle = texts.find(i => i.key === "under_title")?.text;
      slide.addText(
        undersTitle
          ? [
              {
                text: undersTitle,
                options: { color: theme.palette.common.black }
              },
              ...undersArray
            ]
          : undersArray,
        {
          x: "4%",
          y: isNegative ? "35%" : "58%",
          w: "92%",
          h: "20%",
          shape: ppt.ShapeType.roundRect,
          rectRadius: 0.1,
          color: theme.palette.grey[500],
          fontSize: 0.875 * fontSize,
          lineSpacing: 1.375 * 0.875 * fontSize,
          valign: "top",
          fill: theme.palette.negative.light,
          line: { color: theme.palette.negative.main, width: "0.5" }
        }
      );
    }
  }
  if (isSubset) {
    const subsetText = [];
    if (isSubsetTitle) {
      subsetText.push({
        text: subsetTitle.text,
        options: {
          color: theme.palette.common.black,
          bold: true,
          breakLine: true
        }
      });
    }
    if (winners.text.length > 0) {
      subsetText.push({
        text: winnersTitle.text,
        options: { breakLine: true, bold: !isSubsetTitle && true }
      });
      for (let i = 0; i < winners.text.length; i += 1) {
        const driver = winners.text[i];
        const { title, bullets } = driver;
        const winnersArray = getBulletArray(bullets);
        subsetText.push({
          text: title,
          options: { breakLine: true, bold: !isSubsetTitle && true }
        });
        subsetText.push(...winnersArray);
      }
    }
    if (losers.text.length > 0) {
      subsetText.push({
        text: losersTitle.text,
        options: { breakLine: true, bold: !isSubsetTitle && true }
      });
      for (let i = 0; i < losers.text.length; i += 1) {
        const driver = losers.text[i];
        const { title, bullets } = driver;
        const losersArray = getBulletArray(bullets);
        subsetText.push({
          text: title,
          options: { breakLine: true, bold: !isSubsetTitle && true }
        });
        subsetText.push(...losersArray);
      }
    }
    const options = isSubsetTitle
      ? {
          shape: ppt.ShapeType.roundRect,
          rectRadius: 0.1,
          valign: "middle",
          fill: theme.palette.primaryArray[50],
          line: { color: theme.palette.primary.main, width: "0.5" }
        }
      : { valign: "top" };
    slide.addText(subsetText, {
      x: "4%",
      y: isSubsetTitle ? "45%" : "15%",
      w: "92%",
      h: isSubsetTitle ? "45%" : "70%",
      ...options,
      color: theme.palette.grey[500],
      fontSize: 0.875 * fontSize,
      lineSpacing: 1.25 * fontSize
    });
  }
};

const addSingleChart = (ppt, slide, charts, config) => {
  const layout = { left: 4, top: 23, width: 92, height: 60 };
  const isMultiChart = charts.length > 1;
  if (isMultiChart) {
    addChart(ppt, slide, "multi", charts, layout, config);
  } else {
    const chart = charts[0];
    const { type } = chart.style.chart_style;
    addChart(ppt, slide, type, chart, layout, config);
  }
};

const addSubCharts = (ppt, slide, charts, config) => {
  const leftLayout = { left: 4, top: 23, width: 52, height: 60 };
  const rightLayout = { left: 56, top: 23, width: 38, height: 60 };
  const leftChart = charts[0];
  const rightChart = charts[1];
  const leftType = leftChart.style.chart_style.type;
  const rightType = rightChart.style.chart_style.type;
  addChart(
    ppt,
    slide,
    leftType,
    addApplySubchart(leftChart),
    leftLayout,
    config
  );
  addChart(
    ppt,
    slide,
    rightType,
    { ...addApplySubchart(rightChart), isSecond: true },
    rightLayout,
    config
  );
};

const addSection = (ppt, slide, texts, config, alignment) => {
  const { fontSize } = config;
  const sectionValue = texts.find(i => i.key === "section_value");
  const value = sectionValue?.text;
  const isPositiveValue = value && value.startsWith("+");
  const subsetSummary = texts.find(i => i.key === "subset_summary");
  const subsetDetails = texts.find(i => i.key === "subset_details");
  const subsetDetailsList = subsetDetails && subsetDetails?.text?.split("\n");
  const subsetReportText = texts.find(i => i.key === "subset_report_text");
  const subsetReportUrl = texts.find(i => i.key === "subset_report_url");
  const textAlign = getTextAlign(alignment.section);
  const sectionWidth = 100 * alignment.width - 4;
  let xValue = 4;
  if (alignment.section === "right") xValue = 100 - sectionWidth - 4;
  if (value) {
    if (alignment.section === "center") xValue = 45;
    slide.addText(value, {
      x: `${xValue + 1}%`,
      y: "20%",
      w: "10%",
      h: "8%",
      shape: ppt.ShapeType.roundRect,
      rectRadius: 0.1,
      color: theme.palette.common.black,
      fontSize: 1.125 * fontSize,
      align: "center",
      valign: "middle",
      fill: isPositiveValue
        ? theme.palette.positive.light
        : theme.palette.negative.light,
      line: {
        color: isPositiveValue
          ? theme.palette.positive.main
          : theme.palette.negative.main
      }
    });
  }
  if (alignment.section === "center") xValue = 50 - sectionWidth / 2;
  if (subsetSummary) {
    const { text } = subsetSummary;
    slide.addText(text, {
      x: `${xValue}%`,
      y: "22%",
      w: `${sectionWidth}%`,
      h: "8%",
      color: theme.palette.grey[500],
      fontSize,
      align: textAlign,
      valign: "top"
    });
  }
  if (subsetDetailsList) {
    for (let i = 0; i < subsetDetailsList.length; i += 1) {
      const text = subsetDetailsList[i];
      const isPositive = text.includes("+");
      slide.addText(text, {
        x: `${xValue}%`,
        y: `${32 + 10 * i}%`,
        w: `${sectionWidth}%`,
        h: "8%",
        shape: ppt.ShapeType.roundRect,
        rectRadius: 0.1,
        color: theme.palette.common.black,
        fontSize: 0.875 * fontSize,
        align: textAlign,
        valign: "middle",
        fill: isPositive
          ? theme.palette.positive.light
          : theme.palette.negative.light,
        line: {
          color: isPositive
            ? theme.palette.positive.main
            : theme.palette.negative.main
        }
      });
    }
  }
  if (subsetReportUrl) {
    const { text } = subsetReportText;
    const urlText = subsetReportUrl.text;
    slide.addText(
      [
        {
          text,
          options: { hyperlink: { url: urlText } }
        }
      ],
      {
        x: `${xValue}%`,
        y: "55%",
        w: `${sectionWidth}%`,
        h: "8%",
        color: theme.palette.grey[500],
        fontSize,
        align: textAlign,
        valign: "top"
      }
    );
  }
};

const addAppendix = (slide, texts, config) => {
  const { fontSize } = config;
  const appendix = texts.find(i => i.key === "apndx");
  const appendixText = appendix && appendix.text.split("\n");
  if (appendixText && appendixText.length > 0) {
    const textArray = appendixText.map(i => {
      const isBullet = i.startsWith("•");
      const item = isBullet ? i.replace("• ", "").replace("•", "") : i;
      return {
        text: item,
        options: { bullet: isBullet, breakLine: !isBullet }
      };
    });
    slide.addText(textArray, {
      x: "4%",
      y: "15%",
      w: "92%",
      h: "25%",
      color: theme.palette.grey[500],
      fontSize: 0.875 * fontSize,
      lineSpacing: 1.25 * fontSize,
      valign: "top"
    });
  }
};

const addMeasureBox = (ppt, slide, texts, textKey, layout, config) => {
  const { fontSize } = config;
  const { x, y } = layout;
  const textObject = texts.find(i => i.key === textKey);
  const {
    contribution,
    percentage_change: precentChange,
    value_change: valueChange,
    title
  } = textObject.text;
  const color = KANTAR_COLORS[textKey];
  slide.addText(
    [
      { text: title, options: { breakLine: true } },
      { text: valueChange, options: { breakLine: true } },
      { text: "" }
    ],
    {
      shape: ppt.ShapeType.roundRect,
      x: `${x}%`,
      y: `${y}%`,
      w: "18%",
      h: "15%",
      color: theme.palette.common.black,
      fontSize: 0.75 * fontSize,
      lineSpacing: 1.25 * fontSize,
      align: "center",
      valign: "top",
      fill: color,
      rectRadius: 0.1
    }
  );
  slide.addText(
    [
      { text: contribution, options: { color: getColor(precentChange) } },
      { text: "    |    ", options: { bold: false } },
      { text: precentChange, options: { color: getColor(precentChange) } }
    ],
    {
      shape: ppt.ShapeType.roundRect,
      x: `${x + 1}%`,
      y: `${y + 9}%`,
      w: "16%",
      h: "4%",
      fill: { color: theme.palette.grey[50], transparency: 40 },
      color: theme.palette.common.black,
      fontSize: 0.75 * fontSize,
      lineSpacing: 1.125 * fontSize,
      align: "center",
      valign: "middle",
      bold: true
    }
  );
};

const addKantarMeasureTree = (ppt, slide, texts, charts, config) => {
  const { fontSize } = config;
  const layout = { left: 2, top: 23, width: 45, height: 30 };
  const chart = charts[0];
  const { type } = chart.style.chart_style;
  addChart(ppt, slide, type, chart, layout, config);
  addMeasureBox(ppt, slide, texts, "spend", { x: 60, y: 23 }, config);
  addMeasureBox(ppt, slide, texts, "volume", { x: 46, y: 40 }, config);
  addMeasureBox(ppt, slide, texts, "average_price", { x: 74, y: 40 }, config);
  addMeasureBox(
    ppt,
    slide,
    texts,
    "volume_per_buyer",
    { x: 36, y: 57 },
    config
  );
  addMeasureBox(ppt, slide, texts, "buyers", { x: 66, y: 57 }, config);
  addMeasureBox(ppt, slide, texts, "frequency", { x: 22, y: 74 }, config);
  addMeasureBox(ppt, slide, texts, "volume_per_trip", { x: 41, y: 74 }, config);
  addMeasureBox(ppt, slide, texts, "penetration", { x: 61, y: 74 }, config);
  addMeasureBox(
    ppt,
    slide,
    texts,
    "total_households",
    { x: 80, y: 74 },
    config
  );
  const keyText = [
    { text: "Key", options: { breakLine: true, bold: true } },
    {
      text: ">= 3% Change",
      options: { breakLine: true, color: theme.palette.positiveArray[600] }
    },
    {
      text: "<= -3% Change",
      options: { breakLine: true, color: theme.palette.negativeArray[500] }
    }
  ];
  slide.addText(keyText, {
    x: "4%",
    y: "75%",
    w: "16%",
    h: "12%",
    fontSize: 0.75 * fontSize,
    lineSpacing: 1.125 * fontSize,
    color: theme.palette.common.black,
    valign: "top"
  });
};

const addMultipleChartsCompare = (ppt, slide, charts, config) => {
  const { colors, fontSize } = config;
  const numCharts = charts.length;
  const valCharts = charts.filter(i => i.key.includes("value_sales"));
  const volCharts = charts.filter(i => i.key.includes("volume_sales"));
  const avPriceCharts = charts.filter(i => i.key.includes("average_price"));
  const shareCharts = charts.filter(i => i.key.includes("share"));
  const subCharts = [valCharts, volCharts, avPriceCharts, shareCharts].filter(
    i => i.length > 0
  );
  const flatCharts = _.flatten(subCharts);
  const values = flatCharts.map(i => i.style.additional_info?.value);
  const isValues = values.filter(i => i).length > 0;
  const subChartLength = subCharts.length;
  let chartNum = 0;
  const legend = [];
  for (let i = 0; i < subCharts.length; i += 1) {
    const titleLayout = {
      left: 4 + (92 / subChartLength) * i + 1,
      top: 23,
      width: 92 / subChartLength,
      height: 5
    };
    const chartList = subCharts[i];
    const { title } = chartList[0];
    if (title) {
      slide.addText(title, {
        ...getChartLayout(titleLayout),
        fontSize: 14,
        color: theme.palette.grey[500],
        align: "center",
        valign: "top"
      });
    }
    for (let j = 0; j < chartList.length; j += 1) {
      const chart = chartList[j];
      const layout = {
        left: 4 + (92 / numCharts) * chartNum,
        top: isValues ? 33 : 28,
        width: 92 / numCharts,
        height: isValues ? 45 : 50
      };
      if (chart.key.includes("non_promo")) {
        legend.push("Non-Promo");
      } else if (chart.key.includes("promo")) {
        legend.push("Promo");
      } else if (chart.key.includes("___")) {
        legend.push(chart.key.split("___")[0]);
      }
      chartNum += 1;
      const { type } = chart.style.chart_style;
      const subChart = addMaximumValue(chart, chartList);
      addChart(
        ppt,
        slide,
        type,
        addApplySubchart(subChart),
        layout,
        config,
        colors[j]
      );
    }
  }
  if (isValues) {
    for (let i = 0; i < flatCharts.length; i += 1) {
      const value = values[i];
      const isPositive = value && value.startsWith("+");
      if (value) {
        const position = {
          x: `${(92 / flatCharts.length) * i + 46 / flatCharts.length}%`,
          y: "28%"
        };
        slide.addText(value, {
          shape: ppt.ShapeType.roundRect,
          ...position,
          w: "8%",
          h: "5%",
          color: isPositive
            ? theme.palette.positive.main
            : theme.palette.negative.main,
          fontSize: 0.75 * fontSize,
          align: "center",
          valign: "middle",
          fill: isPositive
            ? theme.palette.positive.light
            : theme.palette.negative.light,
          line: {
            color: isPositive
              ? theme.palette.positive.main
              : theme.palette.negative.main
          }
        });
      }
    }
  }
  const uniqueLegend = _.uniq(legend);
  for (let j = 0; j < subCharts.length; j += 1) {
    for (let i = 0; i < uniqueLegend.length; i += 1) {
      slide.addShape(ppt.ShapeType.rect, {
        x: `${5 + i * 10 + (92 / subChartLength) * j}%`,
        y: "80%",
        w: "0.125",
        h: "0.125",
        fill: { color: colors[i] }
      });
      slide.addText(uniqueLegend[i], {
        x: `${6 + i * 10 + (92 / subChartLength) * j}%`,
        y: "78.25%",
        w: "10%",
        h: "5%",
        fontSize: 12,
        valign: "middle"
      });
    }
  }
};

const addContents = (ppt, slide, texts, story, navbar, config) => {
  const contentsList = CONTENT_DETAILS[story];
  let replacements = false;
  if (texts.length > 0) {
    const replacementText = texts.find(i => i.key === "replacement_text")?.text;
    replacements =
      replacementText &&
      Object.assign(
        {},
        ...replacementText.map(i => {
          const [toReplace, replacement] = i.split("___");
          return { [toReplace]: replacement };
        })
      );
  }
  const contentsListKeys = _.keys(contentsList).filter(i =>
    i.match(/\{({*[^{}]*}*)\}/g)
  );
  for (let i = 0; i < contentsListKeys.length; i += 1) {
    const key = contentsListKeys[i];
    const replacedKey = replaceText(contentsList[key].name, replacements);
    contentsList[replacedKey.toLowerCase()] = {
      ...contentsList[key],
      name: replacedKey
    };
  }
  const contents = navbar
    .map(i => i.section.toLowerCase())
    .map(i => contentsList[i])
    .filter(i => i)
    .filter(i => !i.isHidden);
  const middle = Math.ceil(contents.length / 2);
  const left = contents.slice(0, middle);
  const right = contents.slice(middle);
  const leftArray = getContentArray(left, config);
  const rightArray = getContentArray(right, config);
  slide.addText(leftArray, {
    x: "4%",
    y: "15%",
    w: "44%",
    h: "80%",
    color: theme.palette.grey[500],
    valign: "top"
  });
  slide.addText(rightArray, {
    x: "52%",
    y: "15%",
    w: "44%",
    h: "80%",
    color: theme.palette.grey[500],
    valign: "top"
  });
};

const addList = (ppt, slide, texts, config) => {
  const { fontSize } = config;
  const soundbites = texts.find(i => i.key === "soundbites");
  const textArray = soundbites?.text;
  if (soundbites) {
    for (let i = 0; i < textArray.length; i += 1) {
      const isEven = i % 2 === 0;
      const text = textArray[i];
      slide.addText(text, {
        x: isEven ? "4%" : "52%",
        y: `${15 + (isEven ? i / 2 : (i - 1) / 2) * 16}%`,
        w: "44%",
        h: "14%",
        shape: ppt.ShapeType.roundRect,
        rectRadius: 0.1,
        color: theme.palette.grey[500],
        fontSize: 0.875 * fontSize,
        lineSpacing: 1.5 * 0.875 * fontSize,
        align: "center",
        valign: "middle",
        line: { color: theme.palette.grey[200], width: "1.5" }
      });
    }
  }
};

const addDoubleChartTable = (ppt, slide, tables, charts, config) => {
  const table = getTableInfo(tables[0]);
  slide.addTable(
    getTableRows(table.data, table.highlight, table.gradient, config),
    {
      x: "4%",
      y: "65%",
      w: "92%",
      align: "center",
      valign: "middle"
    }
  );
  const leftChart = charts[0];
  const rightChart = charts[1];
  const leftType = leftChart.style.chart_style.type;
  const rightType = rightChart.style.chart_style.type;
  const leftLayout = {
    left: 4,
    top: 23,
    width: 44,
    height: 40
  };
  const rightLayout = {
    left: 52,
    top: 23,
    width: 44,
    height: 40
  };
  addChart(ppt, slide, leftType, leftChart, leftLayout, config);
  addChart(ppt, slide, rightType, rightChart, rightLayout, config);
};

const addStars = (ppt, slide, texts, tables, charts, config) => {
  const { fontSize } = config;
  const numColumns = tables.length;
  const maxColumns = 3;
  for (let i = 0; i < numColumns; i += 1) {
    const chart = charts[i];
    const text = texts[i]?.text;
    const table = getTableInfo(tables[i]);
    slide.addText(table.title, {
      x: `${4 + (92 / maxColumns) * i + 1}%`,
      y: "23%",
      w: `${92 / maxColumns - 2}%`,
      h: "6%",
      color: theme.palette.common.black,
      fontSize: 0.75 * fontSize,
      align: "center"
    });
    slide.addTable(
      getTableRows(table.data, table.highlight, table.gradient, config),
      {
        x: `${4 + (92 / maxColumns) * i + 1}%`,
        y: "30%",
        w: `${92 / maxColumns - 2}%`,
        align: "center",
        valign: "middle"
      }
    );
    slide.addText(text, {
      x: `${4 + (92 / maxColumns) * i + 1}%`,
      y: "48%",
      w: `${92 / maxColumns - 2}%`,
      h: "12%",
      color: theme.palette.grey[500],
      fontSize: 0.75 * fontSize,
      valign: "top"
    });
    if (chart.x.length > 0 && chart.y.length > 0) {
      const { type } = chart.style.chart_style;
      const layout = {
        left: 4 + (92 / maxColumns) * i,
        top: 60,
        width: 92 / maxColumns,
        height: 20
      };
      addChart(ppt, slide, type, chart, layout, config);
    }
  }
  const legendItems = _.uniq(_.flatten(charts.map(i => i.y.map(j => j[0]))));
  const legend = IMPACT_ITEMS.filter(i => legendItems.includes(i.name));
  for (let i = 0; i < legend.length; i += 1) {
    const { label, color } = legend[i];
    slide.addShape(ppt.ShapeType.ellipse, {
      x: `${4 + (92 / legend.length) * i}%`,
      y: "82%",
      w: "0.125",
      h: "0.125",
      fill: { color }
    });
    slide.addText(label, {
      x: `${5 + (92 / legend.length) * i}%`,
      y: "80%",
      w: "10%",
      h: "5%",
      fontSize: 12,
      valign: "middle"
    });
  }
};

const addContent = (
  ppt,
  slide,
  formatKey,
  content,
  config,
  story,
  navbar,
  alignment
) => {
  const { tables, charts, texts } = content;
  if (formatKey === "summary") {
    addSummary(ppt, slide, texts, config);
  } else if (formatKey === "multiple_charts") {
    if (charts.length === 0) return;
    addMultipleCharts(ppt, slide, charts, config);
  } else if (formatKey === "chart_table") {
    addChartTable(ppt, slide, tables, charts, config);
  } else if (formatKey === "dual_table") {
    if (tables.length === 0) return;
    addDualTable(slide, tables, config);
  } else if (formatKey === "dual_chart_table") {
    addDualChartTable(ppt, slide, tables, charts, config);
  } else if (formatKey === "dual_table_chart") {
    addDualTableChart(ppt, slide, tables, charts, config);
  } else if (formatKey === "single_chart") {
    if (charts.length === 0) return;
    addSingleChart(ppt, slide, charts, config);
  } else if (formatKey === "sub_charts") {
    addSubCharts(ppt, slide, charts, config);
  } else if (formatKey === "section") {
    addSection(ppt, slide, texts, config, alignment);
  } else if (formatKey === "appendix") {
    addAppendix(slide, texts, config);
  } else if (formatKey === "kantar_measure_tree") {
    addKantarMeasureTree(ppt, slide, texts, charts, config);
  } else if (formatKey === "multiple_charts_compare") {
    addMultipleChartsCompare(ppt, slide, charts, config);
  } else if (formatKey === "content") {
    addContents(ppt, slide, texts, story, navbar, config);
  } else if (formatKey === "list") {
    addList(ppt, slide, texts, config);
  } else if (formatKey === "double_chart_table") {
    addDoubleChartTable(ppt, slide, tables, charts, config);
  } else if (formatKey === "stars") {
    addStars(ppt, slide, texts, tables, charts, config);
  }
};

export const generatePPT = (report, client, story, navbar) => {
  const ppt = new pptxgen(); // eslint-disable-line new-cap
  const {
    color = theme.palette.primary.main,
    titleColor = theme.palette.primary.main,
    sectionColor = theme.palette.primary.main,
    fontFamily = "Inter Variable",
    fontSize = 16,
    chartColors = CHART_COLORS,
    alignment = ALIGNMENT
  } = clientConfig[client] || {};
  const colors = chartColors.map(i => i.replace("#", ""));
  ppt.layout = "LAYOUT_WIDE";
  ppt.theme = { headFontFace: fontFamily, bodyFontFace: fontFamily };
  addTemplates(ppt, client);
  for (let i = 0; i < report.length; i += 1) {
    const slideContent = report[i];
    const {
      formatkey,
      hidden,
      heading,
      title,
      overflow,
      footer,
      content,
      info_text: infoText,
      warning,
      extra_info: extraInfo
    } = slideContent;
    const removedSlides = [
      "prototype_content",
      "prototype_slide",
      "related_content"
    ];
    if (removedSlides.includes(formatkey)) continue; // eslint-disable-line no-continue
    const masterName = getSlideTemplate(formatkey);
    const slide = ppt.addSlide({ masterName });
    slide.hidden = hidden;
    if (heading) {
      if (i === 0) {
        const date = content?.texts[0]?.text;
        addTitleHeading(
          slide,
          heading,
          fontSize,
          titleColor,
          date,
          title,
          alignment.title
        );
      } else if (formatkey === "section") {
        addSectionHeading(slide, heading, fontSize, sectionColor, alignment);
      } else {
        addHeading(slide, heading, fontSize, color);
      }
    }
    if (footer) addFooter(slide, footer, fontSize, alignment.footer);
    if (title && i !== 0) addTitle(slide, title, fontSize);
    if (overflow) addOverflow(ppt, slide, overflow, fontSize);
    const config = { color, fontFamily, fontSize, colors };
    addContent(
      ppt,
      slide,
      formatkey,
      content,
      config,
      story,
      navbar,
      alignment
    );
    if (infoText) addInfoText(ppt, slide, infoText, fontSize);
    if (warning) addWarning(slide, warning, fontSize, !!title);
    if (extraInfo) addExtraInfo(slide, extraInfo, fontSize);
  }
  return ppt;
};

export default null;

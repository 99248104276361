import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  BrowserRouter,
  Navigate,
  Routes,
  Route,
  useLocation
} from "react-router-dom";
import { connect } from "react-redux";
import { getYear } from "date-fns";
import {
  ThemeProvider,
  StyledEngineProvider,
  CssBaseline,
  Container,
  Typography
} from "@mui/material";
import {
  ABOUT_ROUTE,
  AUTO_RANGING_REPORT_SECTION_ROUTE,
  CATEGORY_OPTIMISATION_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  HOME_ROUTE,
  LOGIN_ROUTE,
  LOGOUT_ROUTE,
  LOOKOUT_PREFERENCES_ROUTE,
  LOOKOUT_ROUTE,
  NEW_AUTO_RANGING_ROUTE,
  NEW_REPORT_ROUTE,
  PREVIOUS_REPORTS_TYPE_ROUTE,
  REPORTS_REVIEWS_BASE_ROUTE,
  RESET_PASSWORD_ROUTE,
  SETTINGS_ROUTE,
  STERLING_OPTION_ROUTE,
  SUPPORT_ROUTE,
  VIEW_REPORT_ROUTE,
  TOOL_SINGLE_ROUTE
} from "constants/viewRoutes";
import CategoryOptimisation from "components/CategoryOptimisation";
import NewReport from "components/NewReport";
import PreviousReports from "components/PreviousReports";
import NewAutoRanging from "components/NewAutoRanging";
import AutoRangingReport from "components/AutoRangingReport";
import StreamlitTool from "components/StreamlitTool";
import Login, { ResetPassword, ForgotPassword } from "components/Login";
import { initLogger, resetLogger } from "logger";
import theme from "./theme";
import { clientConfig, dataSetConfig } from "./config";
import { alertLevels, permissionEnums } from "./constants/enums";
import "./App.css";
import {
  fetchStaticData,
  createPusher,
  removePusher,
  setDataRefresh,
  clearDataRefresh,
  checkDataRefresh,
  checkDataSetDataRefresh,
  addNotification
} from "./actions";
import Notification from "./Notification";
import Home from "./components/Home";
import Settings from "./components/Settings";
import ViewReport from "./components/ViewReport";
import About from "./components/About";
import Support from "./components/Support";
import MenuBar from "./components/MenuBar";
import ReportsReviews from "./components/ReportsReviews";
import Lookout, { LookoutPreferences } from "./components/Lookout";
import SterlingPortfolio from "./components/SterlingPortfolio";
import PrivateRoute from "./components/PrivateRoute";
import DataRefreshDialog from "./components/DataRefreshDialog";

const ScrollToTop = () => {
  const location = useLocation();
  const { pathname, hash } = location;
  useEffect(() => {
    if (!hash) window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [pathname, hash]);

  return null;
};

const App = props => {
  const {
    isStaticData,
    getData,
    client,
    userId,
    dispatchCreatePusher,
    dispatchRemovePusher,
    clientChannel,
    dispatchSetDataRefresh,
    dispatchClearDataRefresh,
    dispatchCheckDataRefresh,
    isDataRefresh,
    dispatchCheckDataSetDataRefresh,
    updatingDataSets,
    updatingStories,
    dispatchAddNotification,
    email,
    prototypes
  } = props;
  const isAdmin =
    localStorage.getItem("user") &&
    JSON.parse(localStorage.getItem("user")).admin;
  const roles =
    (localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user")).roles) ||
    [];
  const isUser = !!localStorage.getItem("user");
  const { permissions } = client ? clientConfig[client] : { permissions: [] };

  const [isDataRefreshOpen, setIsDataRefreshOpen] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.id = "hs-script-loader";
    script.async = true;
    script.defer = true;
    script.src = "https://js.hs-scripts.com/4913136.js";
    document.getElementsByTagName("head")[0].appendChild(script);
  }, []);

  useEffect(() => {
    // get static data when user logs in
    if (isUser && !isStaticData) {
      getData(client);
    }
  }, [isUser, isStaticData]);

  useEffect(() => {
    if (isUser) {
      dispatchCreatePusher(userId, client);
      dispatchCheckDataRefresh();
      initLogger(email, client);
    }
    return () => {
      dispatchRemovePusher();
      resetLogger();
    };
  }, [isUser]);

  useEffect(() => {
    if (clientChannel) {
      clientChannel.bind("dataset_refresh", data => {
        const { dataset, status } = data;
        if (status === "updating") {
          setIsDataRefreshOpen(true);
          dispatchSetDataRefresh(dataset);
        }
        if (status === "available") {
          setIsDataRefreshOpen(false);
          dispatchClearDataRefresh(dataset);
          const dataSetName = dataSetConfig[dataset];
          dispatchAddNotification(`Finished updating ${dataSetName} data.`);
          getData(client);
        }
      });
    }
  }, [clientChannel]);

  useEffect(() => {
    if (isDataRefresh) {
      setInterval(() => {
        for (let i = 0; i < updatingDataSets.length; i += 1) {
          dispatchCheckDataSetDataRefresh(updatingDataSets[i]);
        }
      }, 300000);
    }
  }, [isDataRefresh]);

  const isSterling = roles && roles.includes("sterling");
  const isAutoRanging = roles && roles.includes("auto_ranging");

  const privateRoutes = [
    { path: HOME_ROUTE, component: <Home />, isVisible: true },
    { path: NEW_REPORT_ROUTE, component: <NewReport />, isVisible: true },
    { path: VIEW_REPORT_ROUTE, component: <ViewReport />, isVisible: true },
    {
      path: REPORTS_REVIEWS_BASE_ROUTE,
      component: <ReportsReviews client={client} />,
      isVisible: true
    },
    {
      path: PREVIOUS_REPORTS_TYPE_ROUTE,
      component: <PreviousReports />,
      isVisible: true
    },
    {
      path: LOOKOUT_ROUTE,
      component: <Lookout />,
      isVisible: permissions.includes("lookout")
    },
    {
      path: LOOKOUT_PREFERENCES_ROUTE,
      component: <LookoutPreferences />,
      isVisible: permissions.includes("lookout")
    },
    {
      path: CATEGORY_OPTIMISATION_ROUTE,
      component: <CategoryOptimisation />,
      isVisible: true
    },
    {
      path: NEW_AUTO_RANGING_ROUTE,
      component: <NewAutoRanging />,
      isVisible:
        permissions.includes(permissionEnums.AUTO_RANGING) && isAutoRanging
    },
    {
      path: AUTO_RANGING_REPORT_SECTION_ROUTE,
      component: <AutoRangingReport />,
      isVisible:
        permissions.includes(permissionEnums.AUTO_RANGING) && isAutoRanging
    },
    {
      path: STERLING_OPTION_ROUTE,
      component: <SterlingPortfolio isSterling={isSterling} />,
      isVisible: permissions.includes("sterling")
    },
    { path: ABOUT_ROUTE, component: <About />, isVisible: true },
    { path: SUPPORT_ROUTE, component: <Support />, isVisible: true },
    {
      path: TOOL_SINGLE_ROUTE,
      component: <StreamlitTool />,
      isVisible: permissions.includes(permissionEnums.STREAMLIT)
    },
    {
      path: SETTINGS_ROUTE,
      component: <Settings isAdmin={isAdmin} />,
      isVisible: true
    }
  ].filter(i => i.isVisible);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <ScrollToTop />
          <div className="App">
            {isUser && (
              <MenuBar
                client={client}
                isDataRefresh={isDataRefresh}
                updatingStories={updatingStories}
                prototypes={prototypes}
              />
            )}
            <Notification />
            {isUser && (
              <DataRefreshDialog
                isOpen={isDataRefreshOpen}
                onClose={() => setIsDataRefreshOpen(false)}
                dataSets={updatingDataSets}
              />
            )}
            <Container className="container">
              <Routes>
                {privateRoutes.map(i => (
                  <Route
                    path={i.path}
                    key={i.path}
                    element={<PrivateRoute>{i.component}</PrivateRoute>}
                  />
                ))}
                <Route path={LOGIN_ROUTE} element={<Login />} />
                <Route path={LOGOUT_ROUTE} element={<Login />} />
                <Route
                  path={FORGOT_PASSWORD_ROUTE}
                  element={<ForgotPassword />}
                />
                <Route
                  path={RESET_PASSWORD_ROUTE}
                  element={<ResetPassword />}
                />
                <Route
                  path="/*"
                  element={<Navigate to={HOME_ROUTE} replace />}
                />
              </Routes>
            </Container>
            {isUser && (
              <footer>
                <Typography
                  variant="body2"
                  align="center"
                  color="grey.500"
                  id="footer"
                >
                  &copy; {getYear(new Date())} dijuno
                </Typography>
              </footer>
            )}
          </div>
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

App.propTypes = {
  isStaticData: PropTypes.bool,
  getData: PropTypes.func,
  client: PropTypes.string,
  userId: PropTypes.string,
  dispatchCreatePusher: PropTypes.func,
  dispatchRemovePusher: PropTypes.func,
  clientChannel: PropTypes.shape(),
  dispatchSetDataRefresh: PropTypes.func,
  dispatchClearDataRefresh: PropTypes.func,
  dispatchCheckDataRefresh: PropTypes.func,
  isDataRefresh: PropTypes.bool,
  dispatchCheckDataSetDataRefresh: PropTypes.func,
  updatingDataSets: PropTypes.arrayOf(PropTypes.string),
  updatingStories: PropTypes.arrayOf(PropTypes.string),
  dispatchAddNotification: PropTypes.func,
  email: PropTypes.string,
  prototypes: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string))
};

App.defaultProps = {
  isStaticData: false,
  getData: () => {},
  client: "",
  userId: "",
  dispatchCreatePusher: () => {},
  dispatchRemovePusher: () => {},
  clientChannel: null,
  dispatchSetDataRefresh: () => {},
  dispatchClearDataRefresh: () => {},
  dispatchCheckDataRefresh: () => {},
  isDataRefresh: false,
  dispatchCheckDataSetDataRefresh: () => {},
  updatingDataSets: [],
  updatingStories: [],
  dispatchAddNotification: () => {},
  email: "",
  prototypes: []
};

const mapStateToProps = state => ({
  isStaticData: state.data.isStaticData,
  client: state.user.user.client,
  userId: state.user.user.id,
  clientChannel: state.pusher.clientChannel,
  isDataRefresh: state.data.isUpdatingData,
  updatingDataSets: state.data.updatingDataSets,
  updatingStories: state.data.updatingStories,
  email: state.user.user.email,
  prototypes: state.streamlit.prototypes
});

const mapDispatchToProps = dispatch => ({
  getData: client => dispatch(fetchStaticData(client)),
  dispatchCreatePusher: (userId, client) =>
    dispatch(createPusher(userId, client)),
  dispatchRemovePusher: () => dispatch(removePusher()),
  dispatchSetDataRefresh: dataSet => dispatch(setDataRefresh(dataSet)),
  dispatchClearDataRefresh: dataSet => dispatch(clearDataRefresh(dataSet)),
  dispatchCheckDataRefresh: () => dispatch(checkDataRefresh()),
  dispatchCheckDataSetDataRefresh: dataSet =>
    dispatch(checkDataSetDataRefresh(dataSet)),
  dispatchAddNotification: message =>
    dispatch(addNotification(message, alertLevels.SUCCESS))
});

export { App as AppComponent };
export default connect(mapStateToProps, mapDispatchToProps)(App);

import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  getTickFormat,
  scaleFormatter,
  getStackedMaxMin,
  wrapText,
  getUniqueColors
} from "utils";
import PlotlyChart from "components/PlotlyChart";

const Multi = props => {
  const { data, display, colors } = props;
  const data0 = data[0];
  const data1 = data[1];
  const y2Data = data[1].type.includes("stacked")
    ? _.flattenDeep(getStackedMaxMin(data[1]))
    : _.flattenDeep(data[1].y.map(i => i[1]));
  const yData = data[0].type.includes("stacked")
    ? _.flattenDeep(getStackedMaxMin(data[0]))
    : _.flattenDeep(data[0].y.map(i => i[1]));
  let y2Range;
  let yRange;
  if (_.min([...yData, ...y2Data]) < 0) {
    const { minScale: minScale2, maxScale: maxScale2 } = scaleFormatter(y2Data);
    y2Range = [minScale2, maxScale2];
    const { minScale, maxScale } = scaleFormatter(yData);
    yRange = [minScale, maxScale];
  }
  const isKantar =
    display[0].apply.includes("kantar") || display[1].apply.includes("kantar");
  const {
    prefix: prefix0,
    suffix: suffix0,
    format: format0
  } = getTickFormat(display[0].labels_style?.number_format);
  const {
    prefix: prefix1,
    suffix: suffix1,
    format: format1
  } = getTickFormat(display[1].labels_style?.number_format);
  return (
    <PlotlyChart
      data={[
        ...data0.y.map((e, key) => {
          const isColorMap = !_.isEmpty(display[0].chart_style.color_map);
          const colorMap = display[0].chart_style.color_map;
          const uniqueColors = getUniqueColors(colors, colorMap);
          let colour = uniqueColors[key % uniqueColors.length];
          if (isColorMap) {
            colour =
              colorMap[key] === undefined
                ? uniqueColors[key % uniqueColors.length]
                : `#${colorMap[key]}`;
          }
          return data0.type === "line"
            ? {
                x: data0.x,
                y: e[1],
                cliponaxis: false,
                name: e[0],
                marker: { color: colour }
              }
            : {
                x: data0.x,
                y: e[1],
                type: "bar",
                cliponaxis: false,
                name: e[0],
                marker: { color: colour }
              };
        }),
        ...data1.y.map((e, key) => {
          const isColorMap = !_.isEmpty(display[1].chart_style.color_map);
          const colorMap = display[1].chart_style.color_map;
          const uniqueColors = getUniqueColors(colors, colorMap);
          let colour =
            uniqueColors[(key + data1.y.length) % uniqueColors.length];
          if (isColorMap) {
            colour =
              colorMap[key] === undefined
                ? uniqueColors[key % uniqueColors.length]
                : `#${colorMap[key]}`;
          } else if (data1.type === "line") {
            colour = uniqueColors[(key + data0.y.length) % uniqueColors.length];
          }
          return data1.type === "line"
            ? {
                x: data0.x,
                y: e[1],
                yaxis: "y2",
                cliponaxis: false,
                name: e[0],
                marker: { color: colour }
              }
            : {
                x: data0.x,
                y: e[1],
                type: "bar",
                yaxis: "y2",
                cliponaxis: false,
                name: e[0],
                marker: { color: colour }
              };
        })
      ]}
      layout={{
        xaxis: {
          type: "category",
          showline: false,
          tickfont: { size: 12 },
          ticklen: 2,
          tickcolor: "transparent",
          automargin: true,
          zeroline: true,
          dtick: 1,
          fixedrange: true
        },
        yaxis: {
          showline: true,
          tickfont: { size: 12 },
          ticklen: 5,
          zeroline: true,
          overlaying: data0.type === "line" && "y2",
          showgrid: false,
          side: "left",
          rangemode: "tozero",
          range: yRange,
          title: wrapText(display[0].yAxisTitle, 50),
          tickprefix: !format0 && prefix0,
          tickformat: format0,
          ticksuffix: suffix0,
          automargin: true,
          fixedrange: true
        },
        yaxis2: {
          showline: true,
          tickfont: { size: 12 },
          ticklen: 5,
          zeroline: true,
          overlaying: data1.type === "line" && "y",
          side: "right",
          showgrid: false,
          rangemode: "tozero",
          range: y2Range,
          title: wrapText(display[1].yAxisTitle, 50),
          tickprefix: !format1 && prefix1,
          tickformat: format1,
          ticksuffix: suffix1,
          automargin: true,
          fixedrange: true
        },
        legend: {
          orientation: "h",
          xanchor: "center",
          y: isKantar ? -0.4 : 1.2,
          x: 0.5,
          font: { size: 12 }
        },
        barmode: data0.type === "stacked_column" && "relative"
      }}
    />
  );
};

Multi.propTypes = {
  data: PropTypes.shape(),
  display: PropTypes.shape(),
  colors: PropTypes.arrayOf(PropTypes.string)
};

Multi.defaultProps = {
  data: {},
  display: {},
  colors: []
};

export default Multi;

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Favicon from "react-favicon";
import {
  Link as RouterLink,
  useLocation,
  generatePath
} from "react-router-dom";
import {
  IconButton,
  Tooltip,
  Typography,
  Link,
  Box,
  Stack,
  Button
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Menu, Paper } from "components/BaseComponents";
import {
  DownloadIcon,
  EditIcon,
  SuggestionIcon,
  PerformanceIcon,
  BrandBattlesIcon,
  TrendIcon,
  ViewIcon as DefaultIcon,
  StarIcon,
  WarningIcon,
  HomeIcon
} from "components/Icons";
import {
  NEW_REPORT_ROUTE,
  PREVIOUS_REPORTS_TYPE_ROUTE,
  EDIT_REPORT_TYPE_ROUTE,
  HOME_ROUTE,
  EDIT_REPORT_FOCUS_TYPE_ROUTE
} from "constants/viewRoutes";
import {
  fetchReport,
  addToFavourites,
  goToSlide,
  generatePowerPoint,
  getReport
} from "actions";
import { loading } from "images";
import { getFocusFromQuery, getStoryFromQuery } from "utils";
import { getStoryConfig } from "config";
import { REPORT_COLORS } from "theme";
import Presentation from "./Presentation";
import PresentationNavLeft from "./PresentationNavLeft";
import PresentationNavRight from "./PresentationNavRight";
import "./Presentation.css";
import PresentationError from "./PresentationError";
import PresentationNav from "./PresentationNav";
import ReportNavigation from "./ReportNavigation";

const getSuggestionIcon = suggestion => {
  switch (suggestion) {
    case "performance":
      return (
        <PerformanceIcon
          sx={{ color: REPORT_COLORS.performance }}
          fontSize="small"
        />
      );
    case "brand_battles":
      return (
        <BrandBattlesIcon
          sx={{ color: REPORT_COLORS.brandBattles }}
          fontSize="small"
        />
      );
    case "trend":
      return <TrendIcon sx={{ color: REPORT_COLORS.trend }} fontSize="small" />;
    default:
      return <DefaultIcon sx={{ color: "primary.main" }} fontSize="small" />;
  }
};

const ViewReport = props => {
  const {
    reportIsLoading,
    reportError,
    userId,
    client,
    dispatchFetchReport,
    dispatchGetReport,
    goToFirstSlide,
    date,
    report,
    addFavourite,
    download,
    slideIndex,
    reportTitle,
    reportQuery,
    reportId,
    reportStory,
    isDownloading,
    reportNavigation,
    toSlide,
    userChannel,
    isDataRefresh,
    updatingStories
  } = props;
  const location = useLocation();
  const queryString = location.search.slice(1);
  const story = getStoryFromQuery(location.search);
  const { isFocus, focusType } = getFocusFromQuery(location.search);
  const { dataSet, type, icon: ReportIcon } = getStoryConfig(story);
  const [selectedSlideKey, setSelectedSlideKey] = useState("");
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [isNavigation, setIsNavigation] = useState(true);

  useEffect(() => {
    if (userChannel && reportId) {
      userChannel.bind("reports", data => {
        const { id, status, text } = data;
        if (reportId === id) {
          dispatchGetReport(
            client,
            story,
            id,
            queryString,
            userId,
            status,
            text
          );
          if (slideIndex > 0) {
            goToFirstSlide();
          }
        }
      });
    }
  }, [userChannel, reportId]);

  useEffect(() => {
    if (userChannel) {
      dispatchFetchReport(queryString, date[dataSet], client);
    }
  }, [userChannel]);

  useEffect(() => {
    if (!reportIsLoading) document.title = `dijuno: ${reportTitle}`;
    return () => {
      document.title = "dijuno";
      const favicon = document.querySelector("link[rel~='icon']");
      if (favicon) {
        favicon.href = "favicon.ico";
      }
    };
  }, [reportTitle, reportIsLoading]);

  useEffect(() => {
    const activeSlideKey = report[slideIndex]?.slidekey;
    setSelectedSlideKey(activeSlideKey);
  }, [slideIndex, reportIsLoading]);

  const suggestedReports =
    report[slideIndex]?.suggestion?.map(i => ({
      title: i.nl.replace("{bold_target}", "{bold}"),
      bold: i.bold_target,
      path: i.link,
      icon: getSuggestionIcon(i.type)
    })) || [];

  const handleSlideClicked = slide => {
    const activeSlideIndex = report.findIndex(
      item => item.slidekey === slide.key
    );
    if (activeSlideIndex >= 0) toSlide(activeSlideIndex, report, report.length);
    setSelectedSlideKey(slide.key);
    setIsNavigation(false);
  };

  if (isDataRefresh && updatingStories.includes(story.toLowerCase())) {
    return (
      <Stack alignItems="center">
        <Favicon url="favicon.ico" />
        <Paper styles={{ width: 600, mt: 10, textAlign: "center" }}>
          <Box sx={{ color: "neutral.main" }}>
            <WarningIcon
              sx={{
                borderRadius: "50%",
                p: 1.25,
                bgcolor: "neutralArray.100",
                border: 10,
                borderColor: "neutralArray.50",
                fontSize: 70
              }}
            />
          </Box>
          <Typography color="grey.900" variant="h3" gutterBottom>
            Data Refresh in Progress
          </Typography>
          <Typography variant="body1" color="grey.500">
            We&apos;re currently updating the data for this report. You can
            still run non-dependent reports. Your report should be up and
            running shortly. Thank you for your patience!
          </Typography>
          <Button
            variant="contained"
            sx={{ mt: 2 }}
            component={RouterLink}
            to={HOME_ROUTE}
            startIcon={<HomeIcon />}
          >
            Back Home
          </Button>
        </Paper>
      </Stack>
    );
  }
  if (reportIsLoading) {
    return (
      <Stack alignItems="center">
        <Favicon url={["favicon0.ico", "favicon1.ico", "favicon2.ico"]} />
        <img style={{ height: 500 }} alt="loading" src={loading} />
        <Typography variant="h3" color="secondary.main" sx={{ mt: -5, mb: 2 }}>
          Generating Report...
        </Typography>
        <Typography variant="body1" color="grey.500">
          You can{" "}
          <Link
            href={NEW_REPORT_ROUTE}
            target="_blank"
            rel="noreferrer"
            underline="hover"
            color="secondary"
            fontWeight="bold"
          >
            run another report
          </Link>{" "}
          or view your{" "}
          <Link
            href={generatePath(PREVIOUS_REPORTS_TYPE_ROUTE, {
              reportType: type
            })}
            target="_blank"
            rel="noreferrer"
            underline="hover"
            color="secondary"
            fontWeight="bold"
          >
            recent reports
          </Link>{" "}
          while you are waiting.
        </Typography>
      </Stack>
    );
  }
  if (reportError) {
    return (
      <Stack alignItems="center">
        <Favicon url="favicon.ico" />
        <PresentationError errorMessage={reportError} />
      </Stack>
    );
  }
  return (
    <Stack direction="row" alignItems="stretch" spacing={1.5}>
      <Favicon url="favicon.ico" />
      <Paper variant="elevated" styles={{ width: 1, minWidth: 285, pt: 1 }}>
        <ReportNavigation
          listItems={reportNavigation}
          selectedSlideKey={selectedSlideKey}
          onSlideClicked={handleSlideClicked}
          isNavigation={isNavigation}
        />
      </Paper>
      <Stack alignItems="stretch" spacing={1.5}>
        <Stack direction="row" alignItems="stretch" spacing={1.5}>
          <Paper variant="elevated">
            <Tooltip title={reportTitle}>
              <Stack
                direction="row"
                alignItems="center"
                sx={{ height: 1, px: 1.5 }}
                spacing={0.75}
              >
                <ReportIcon sx={{ fontSize: 16 }} />
                <Typography
                  variant="subtitle2"
                  color="grey.900"
                  noWrap
                  sx={{ width: 550 }}
                >
                  {reportTitle}
                </Typography>
              </Stack>
            </Tooltip>
          </Paper>
          <Paper variant="elevated" styles={{ width: 65 }}>
            <Stack justifyContent="center" sx={{ height: 1 }}>
              <PresentationNav
                slideIndex={slideIndex}
                reportLength={report.length}
              />
            </Stack>
          </Paper>
          <Paper variant="elevated">
            <Stack
              alignContent="center"
              justifyContent="center"
              sx={{ height: 1 }}
            >
              <Tooltip title="Add to favourites">
                <IconButton
                  size="large"
                  sx={{
                    color: "grey.500",
                    "&:hover": { color: "neutral.main" }
                  }}
                  onClick={() =>
                    addFavourite({ query: reportQuery, source: "report" })
                  }
                >
                  <StarIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          </Paper>
          <Paper variant="elevated">
            <LoadingButton
              color="secondary"
              size="large"
              startIcon={<DownloadIcon />}
              sx={{ px: 1.5 }}
              onClick={() => {
                const slideKey = report[slideIndex].slidekey;
                download(
                  report,
                  reportId,
                  isFocus ? "focus" : reportStory,
                  reportTitle,
                  userId,
                  client,
                  slideIndex,
                  slideKey,
                  reportNavigation
                );
              }}
              loading={isDownloading}
              loadingPosition="start"
              id="download" // for load-runner
            >
              Download
            </LoadingButton>
          </Paper>
          <Paper variant="elevated">
            <Button
              color="disabled"
              size="large"
              startIcon={<EditIcon />}
              sx={{ px: 1.5, color: "grey.500" }}
              component={RouterLink}
              to={
                isFocus
                  ? `${generatePath(EDIT_REPORT_FOCUS_TYPE_ROUTE, {
                      focusType
                    })}${location.search}`
                  : `${generatePath(EDIT_REPORT_TYPE_ROUTE, {
                      reportType: type
                    })}${location.search}`
              }
              rel="noreferrer"
              target="_blank"
            >
              Edit
            </Button>
          </Paper>
          <Paper variant="elevated">
            <Button
              size="large"
              startIcon={
                <SuggestionIcon
                  sx={{
                    color: suggestedReports.length === 0 ? "grey.400" : "white"
                  }}
                />
              }
              onClick={e => setAnchorMenu(e.currentTarget)}
              variant="contained"
              color="jade"
              sx={{ px: 1.5 }}
              disabled={suggestedReports.length === 0}
            >
              Suggestions
            </Button>
            <Menu
              menuItems={suggestedReports}
              handleClose={() => setAnchorMenu(null)}
              anchorEl={anchorMenu}
              styles={{ mt: 1.5 }}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              newTab
            />
          </Paper>
        </Stack>
        <Box
          sx={{
            position: "relative",
            "&:hover": { "& .action-keys": { opacity: 1 } }
          }}
        >
          <Box
            className="action-keys"
            sx={{
              position: "absolute",
              left: "24px",
              top: "50%",
              transform: "translate(0, -50%)",
              zIndex: 100,
              opacity: 0,
              transition: "all 0.5s"
            }}
          >
            <PresentationNavLeft
              activeSlideIndex={slideIndex}
              report={report}
              reportId={reportId}
              userId={userId}
              story={reportStory}
              client={client}
              setIsNavigation={setIsNavigation}
            />
          </Box>
          <Presentation
            activeSlideIndex={slideIndex}
            report={report}
            client={client}
            story={reportStory}
            contents={reportNavigation}
            navigateToSlide={handleSlideClicked}
          />
          <Box
            className="action-keys"
            sx={{
              position: "absolute",
              right: "24px",
              top: "50%",
              transform: "translate(0, -50%)",
              zIndex: 100,
              opacity: 0,
              transition: "all 0.5s"
            }}
          >
            <PresentationNavRight
              activeSlideIndex={slideIndex}
              report={report}
              reportId={reportId}
              userId={userId}
              story={reportStory}
              client={client}
              setIsNavigation={setIsNavigation}
            />
          </Box>
        </Box>
      </Stack>
    </Stack>
  );
};

ViewReport.propTypes = {
  reportNavigation: PropTypes.arrayOf(PropTypes.shape()),
  report: PropTypes.arrayOf(PropTypes.shape()),
  reportIsLoading: PropTypes.bool,
  reportError: PropTypes.string,
  dispatchFetchReport: PropTypes.func,
  dispatchGetReport: PropTypes.func,
  slideIndex: PropTypes.number,
  goToFirstSlide: PropTypes.func,
  toSlide: PropTypes.func,
  userId: PropTypes.string,
  addFavourite: PropTypes.func,
  date: PropTypes.shape(),
  download: PropTypes.func,
  reportTitle: PropTypes.string,
  reportQuery: PropTypes.string,
  reportId: PropTypes.string,
  client: PropTypes.string,
  reportStory: PropTypes.string,
  isDownloading: PropTypes.bool,
  userChannel: PropTypes.shape(),
  isDataRefresh: PropTypes.bool,
  updatingStories: PropTypes.arrayOf(PropTypes.string)
};

ViewReport.defaultProps = {
  report: [],
  reportNavigation: [],
  reportIsLoading: true,
  reportError: "",
  dispatchFetchReport: () => {},
  dispatchGetReport: () => {},
  slideIndex: 0,
  goToFirstSlide: () => {},
  toSlide: () => {},
  userId: "",
  addFavourite: () => {},
  date: {},
  download: () => {},
  reportTitle: "",
  reportQuery: "",
  reportId: "",
  client: "",
  reportStory: "",
  isDownloading: false,
  userChannel: null,
  isDataRefresh: false,
  updatingStories: []
};

const mapStateToProps = state => ({
  reportNavigation: state.report.reportNavigation,
  report: state.report.reportContent,
  reportIsLoading: state.report.isLoading,
  reportError: state.report.error,
  reportTitle: state.report.reportTitle,
  reportQuery: state.report.reportQuery,
  reportId: state.report.reportId,
  reportStory: state.report.reportStory,
  slideIndex: state.presentation.activeSlideIndex,
  userId: state.user.user.id,
  client: state.user.user.client,
  date: state.data.date,
  isDownloading: state.presentation.isLoading,
  userChannel: state.pusher.userChannel,
  isDataRefresh: state.data.isUpdatingData,
  updatingStories: state.data.updatingStories
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatchFetchReport: (queryString, date, client) =>
    dispatch(fetchReport(queryString, date, client)),
  dispatchGetReport: (
    client,
    story,
    reportId,
    queryString,
    userId,
    status,
    error
  ) =>
    dispatch(
      getReport(client, story, reportId, queryString, userId, status, error)
    ),
  goToFirstSlide: () => dispatch(goToSlide(0)),
  addFavourite: report => dispatch(addToFavourites(report)),
  download: (
    report,
    id,
    story,
    title,
    userId,
    client,
    slideIndex,
    slideKey,
    navbar
  ) => {
    dispatch(
      generatePowerPoint(
        report,
        id,
        story,
        title,
        userId,
        client,
        slideIndex,
        slideKey,
        navbar
      )
    );
  },
  toSlide: (slideIndex, report, slideCount) => {
    const { reportId, userId, story, client } = ownProps;
    dispatch(
      goToSlide(
        slideIndex,
        report,
        "next",
        slideCount,
        reportId,
        userId,
        story,
        client
      )
    );
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewReport);

import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Favicon from "react-favicon";
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";
import _ from "lodash";
import { Stack, Typography } from "@mui/material";
import { Paper, Tabs } from "components/BaseComponents";
import {
  NEW_AUTO_RANGING_FINAL_ROUTE,
  NEW_AUTO_RANGING_INITIAL_ROUTE,
  EDIT_AUTO_RANGING_INITIAL_ROUTE,
  AUTO_RANGING_REPORT_SECTION_ROUTE,
  AUTO_RANGING_REPORT_INITIAL_ROUTE
} from "constants/viewRoutes";
import { loading } from "images";
import PresentationError from "components/ViewReport/PresentationError";
import {
  changeAutoRangingFavoriteStatus,
  clearAutoRangingConstraints,
  getAutoRangingReport
} from "actions";
import Header from "./Header";
import Summary from "./Summary";
import Compare from "./Compare";
import BayPlanChanges from "./BayPlanChanges";
import Charts from "./Charts";
import GapAnalysis from "./GapAnalysis";

const reportTabs = [
  { label: "Summary", value: "summary" },
  { label: "Compare", value: "compare" },
  { label: "Bay Plan Changes", value: "bay-plan-changes" },
  { label: "Charts", value: "charts" },
  { label: "Gap Analysis", value: "gap-analysis" }
];

const AutoRangingReport = props => {
  const {
    client,
    report,
    userTracking,
    isLoading,
    isLoadingFavourite,
    dispatchGetAutoRangingReport,
    dispatchClearAutoRangingConstraints,
    dispatchChangeAutoRangingFavoriteStatus,
    userChannel,
    error,
    userId
  } = props;
  const { reportId, story } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { section } = useParams();
  const { from = "" } = state || {};

  useEffect(() => {
    dispatchClearAutoRangingConstraints();
  }, []);

  const redirect = () => {
    dispatchClearAutoRangingConstraints();
    navigate(generatePath(EDIT_AUTO_RANGING_INITIAL_ROUTE, { id: reportId }), {
      replace: true
    });
  };

  useEffect(() => {
    if (from === NEW_AUTO_RANGING_FINAL_ROUTE) {
      navigate(
        generatePath(AUTO_RANGING_REPORT_INITIAL_ROUTE, {
          reportId,
          story
        }),
        { replace: true }
      );
      return;
    }
    dispatchGetAutoRangingReport({ client, story, reportId, userId, redirect });
  }, []);

  useEffect(() => {
    if (userChannel) {
      userChannel.bind("reports", data => {
        const { id, status, text: responseMessage } = data;
        if (id === reportId) {
          dispatchGetAutoRangingReport({
            client,
            story,
            reportId,
            status,
            responseMessage,
            userId,
            isPusher: true
          });
        }
      });
    }
  }, [userChannel]);

  const onRefreshData = () =>
    dispatchGetAutoRangingReport({ client, story, reportId, userId, redirect });

  const onAddFavourite = () =>
    dispatchChangeAutoRangingFavoriteStatus({
      constraints: report.constraints,
      operation: "add"
    });

  if (isLoading)
    return (
      <Stack alignItems="center">
        <Favicon url={["/favicon0.ico", "/favicon1.ico", "/favicon2.ico"]} />
        <img style={{ height: 500 }} alt="loading" src={loading} />
        <Typography variant="h3" color="secondary.main" sx={{ mt: -5, mb: 2 }}>
          Generating Report...
        </Typography>
        <Typography variant="body1" color="grey.500">
          Please avoid refreshing the page while the loading screen is active.
        </Typography>
      </Stack>
    );

  if (error || error?.message || _.isEmpty(report))
    return (
      <Stack alignItems="center">
        <Favicon url="/favicon.ico" />
        <PresentationError
          errorMessage={error.message}
          newReportLink={NEW_AUTO_RANGING_INITIAL_ROUTE}
        />
      </Stack>
    );

  return (
    <Fragment>
      <Favicon url="/favicon.ico" />
      <Header
        report={report}
        reportId={reportId}
        onRefreshData={onRefreshData}
        userTracking={userTracking}
        onAddFavourite={onAddFavourite}
        isLoadingFavourite={isLoadingFavourite}
      />
      <Paper>
        <Tabs
          activeTab={section}
          onChange={(e, value) =>
            navigate(
              generatePath(AUTO_RANGING_REPORT_SECTION_ROUTE, {
                reportId,
                story,
                section: value
              })
            )
          }
          tabs={reportTabs}
          styles={{ mt: -1, mb: 1 }}
          disableFocusRipple
          disableRipple
        />
        {section === "summary" && <Summary report={report} />}
        {section === "compare" && <Compare report={report} />}
        {section === "bay-plan-changes" && <BayPlanChanges report={report} />}
        {section === "charts" && <Charts report={report} />}
        {section === "gap-analysis" && <GapAnalysis report={report} />}
      </Paper>
    </Fragment>
  );
};

AutoRangingReport.propTypes = {
  client: PropTypes.string,
  report: PropTypes.shape(),
  userTracking: PropTypes.shape(),
  isLoading: PropTypes.bool,
  isLoadingFavourite: PropTypes.bool,
  dispatchGetAutoRangingReport: PropTypes.func,
  dispatchClearAutoRangingConstraints: PropTypes.func,
  dispatchChangeAutoRangingFavoriteStatus: PropTypes.func,
  userChannel: PropTypes.shape(),
  error: PropTypes.string,
  userId: PropTypes.string
};

AutoRangingReport.defaultProps = {
  client: "",
  report: {},
  userTracking: {},
  isLoading: true,
  isLoadingFavourite: false,
  dispatchGetAutoRangingReport: () => {},
  dispatchClearAutoRangingConstraints: () => {},
  dispatchChangeAutoRangingFavoriteStatus: () => {},
  userChannel: null,
  error: "",
  userId: ""
};

const mapStateToProps = state => ({
  client: state.user.user.client,
  report: state.autoRanging.finalReportResult.data,
  userTracking: state.autoRanging.userTracking,
  isLoading: state.autoRanging.finalReportResult.isLoading,
  isLoadingFavourite: state.report.isLoadingChangeARFavourite,
  error: state.autoRanging.finalReportResult.error,
  userChannel: state.pusher.userChannel,
  userId: state.user.user.id
});

const mapDispatchToProps = dispatch => ({
  dispatchGetAutoRangingReport: ({
    client,
    story,
    reportId,
    status,
    responseMessage,
    userId,
    redirect,
    isPusher
  }) =>
    dispatch(
      getAutoRangingReport({
        client,
        story,
        reportId,
        status,
        responseMessage,
        userId,
        redirect,
        isPusher
      })
    ),
  dispatchClearAutoRangingConstraints: () =>
    dispatch(clearAutoRangingConstraints()),
  dispatchChangeAutoRangingFavoriteStatus: ({ constraints, operation }) =>
    dispatch(changeAutoRangingFavoriteStatus({ constraints, operation }))
});

export default connect(mapStateToProps, mapDispatchToProps)(AutoRangingReport);

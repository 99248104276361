import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  wrapText,
  getTickFormat,
  getUniqueColors,
  getIndividualMap
} from "utils";
import PlotlyChart from "components/PlotlyChart";

const StackedColumn = props => {
  const { data, display, colors } = props;
  const { isSmall } = display;
  const isWaterfall = display.apply?.includes("waterfall");
  const isColorMap = !_.isEmpty(display.chart_style.color_map);
  const colorMap = display.chart_style.color_map;
  const uniqueColors = getUniqueColors(colors, colorMap);
  const { minimum_scale: min, maximum_scale: max } = display.values_style;
  const isIndividualMap = !_.isEmpty(display.labels_style.individual_map);
  const { prefix, suffix } = getTickFormat(display.labels_style.number_format);
  return (
    <PlotlyChart
      data={data.y.map((e, key) => {
        let colour = uniqueColors[key % uniqueColors.length];
        if (isWaterfall) {
          colour = _.isEqual(data.y[key][0], "white")
            ? "transparent"
            : data.y[key][0];
        } else if (isColorMap) {
          colour =
            colorMap[key] === undefined
              ? uniqueColors[key % uniqueColors.length]
              : `#${colorMap[key]}`;
        }
        return {
          x: data.x.map(i => wrapText(i, isSmall ? 12 : 30)),
          y: e[1],
          type: "bar",
          text:
            isIndividualMap &&
            getIndividualMap(
              display.labels_style.individual_map[key],
              data.x.length
            ),
          textfont: { color: "#FFFFFF", size: 14 },
          textangle: 0,
          insidetextanchor: "middle",
          cliponaxis: false,
          name: isIndividualMap ? wrapText(e[0], 15) : e[0],
          marker: { color: colour }
        };
      })}
      layout={{
        xaxis: {
          type: "category",
          showticklabels: !display.align,
          ticklen: 2,
          tickcolor: "transparent",
          tickfont: { size: 12 },
          automargin: true,
          showgrid: false,
          fixedrange: true
        },
        yaxis: {
          showticklabels: !isWaterfall && !isIndividualMap,
          ticklen: 5,
          tickcolor: "transparent",
          tickprefix: prefix,
          ticksuffix: suffix,
          zeroline: !isWaterfall,
          automargin: true,
          showgrid: !isIndividualMap,
          range: [min, max],
          fixedrange: true
        },
        barmode: "relative",
        showlegend: !isWaterfall,
        legend: isIndividualMap
          ? { x: 1, y: 0.5, font: { size: 10 } }
          : { orientation: "h", xanchor: "center", y: 1.2, x: 0.5 },
        margin:
          display.align || isSmall
            ? { t: isSmall ? 0 : 40, b: 40, r: 0, l: 0 }
            : { t: 40, b: 40, r: 20, l: 20 }
      }}
    />
  );
};

StackedColumn.propTypes = {
  data: PropTypes.shape(),
  display: PropTypes.shape(),
  colors: PropTypes.arrayOf(PropTypes.string)
};

StackedColumn.defaultProps = {
  data: {},
  display: {},
  colors: []
};

export default StackedColumn;

import { getHours } from "date-fns";
import { storyEnums } from "constants/enums";
import { getStoryConfig } from "config";

/**
 * Get user greeting based on time of day
 */
export const getGreeting = () => {
  const hour = getHours(new Date());
  if (hour >= 2 && hour < 12) {
    return "Good Morning";
  }
  if (hour >= 12 && hour < 18) {
    return "Good Afternoon";
  }
  return "Good Evening";
};

/**
 * Create a query string from a list of constraints
 * @param  {Array} constraints    Array of constraints, each of which is an object
 * @return {String}               URI encoded query string for the report URL
 */
export const buildQueryString = constraints =>
  constraints
    .sort((a, b) => {
      if (a.subsection < b.subsection) return -1;
      if (a.subsection > b.subsection) return 1;
      return 0;
    }) // order so query always the same for a set group of constraints
    .map(
      i =>
        `${i.prefix ? `${i.prefix}=` : ""}${encodeURIComponent(
          i.subsection
        )}=${encodeURIComponent(i.name)}`
    )
    .join("&");

/**
 * Get report story from query string
 */
export const getStoryFromQuery = query => {
  const urlParams = new URLSearchParams(query);
  const params = Object.fromEntries(urlParams);
  return params?.story?.toLowerCase();
};

/**
 * Get report focus details from query string
 */
export const getFocusFromQuery = query => {
  const urlParams = new URLSearchParams(query);
  const params = Object.fromEntries(urlParams);
  const isFocus = !!params?.focus_report;
  return { isFocus, focusType: params?.focus_report?.toLowerCase() };
};

/**
 * Get report constraints from query string
 */
export const getConstraintsFromQuery = query => {
  const urlParams = new URLSearchParams(query);
  const params = Object.fromEntries(urlParams);
  const { story: queryStory } = params;
  const story = queryStory?.toLowerCase();

  if (story === storyEnums.BB) {
    const {
      retailer: market,
      period,
      metric_name: metric,
      product_a: prodA,
      product_b: prodB
    } = params || {};
    const productA = prodA
      ? {
          name: prodA.split("=")[1],
          subsection: prodA.split("=")[0]
        }
      : null;
    const productB = prodB
      ? {
          name: prodB.split("=")[1],
          subsection: prodB.split("=")[0]
        }
      : null;
    [
      "story",
      "retailer",
      "period",
      "metric_name",
      "product_a",
      "product_b"
    ].forEach(prop => delete params[prop]);
    const context = [];
    Object.entries(params).forEach(([key, value]) => {
      context.push({ name: value, subsection: key });
    });
    return { story, market, period, metric, productA, productB, context };
  }

  if (story === storyEnums.PRR) {
    const {
      temperature,
      consumption_type: consumptionType,
      category,
      sector,
      retailer: market,
      metric_name: metric,
      period,
      implementation_date: implementationDate,
      hier1: hierarchy1,
      hier2: hierarchy2
    } = params || {};
    return {
      story,
      market,
      metric,
      period,
      implementationDate,
      temperature,
      consumptionType,
      category,
      sector,
      hierarchy1,
      hierarchy2
    };
  }

  if ([storyEnums.IDA, storyEnums.CGA, storyEnums.TREND].includes(story)) {
    const { channel } = getStoryConfig(story);
    const {
      period,
      metric_name: metric,
      retailer: market,
      focus_report: focusReport
    } = params || {};
    ["story", "retailer", "period", "metric_name"].forEach(
      prop => delete params[prop]
    );
    if (focusReport) delete params.focus_report;
    const product = [];
    const context = [];
    Object.entries(params).forEach(([key, value]) => {
      if (key.startsWith("context")) {
        const [subsection, name] = value.split("=");
        context.push({ name, subsection });
      } else {
        product.push({ name: value, subsection: key });
      }
    });
    if (focusReport) {
      return {
        story,
        period,
        metric,
        market,
        product,
        context,
        channel,
        focusReport
      };
    }
    return {
      story,
      period,
      metric,
      market,
      product,
      context,
      channel
    };
  }

  return {};
};

/**
 * Rebuild query string based on old query string and focus report
 */
export const rebuildQueryString = (query, focusType) => {
  const initialConstraints = getConstraintsFromQuery(query);
  const { story, focusReport } = initialConstraints;

  if (!focusReport && !focusType) return query;

  if (focusReport === focusType) return query;

  if ([storyEnums.IDA, storyEnums.CGA].includes(story)) {
    const { product, context, market, period, metric } = initialConstraints;
    const constraints = [
      ...product,
      ...context.map((i, k) => ({ ...i, prefix: `context${k}` })),
      { name: market, subsection: "retailer" },
      { name: period, subsection: "period" },
      { name: metric, subsection: "metric_name" }
    ];
    if (focusType)
      constraints.push({ name: focusType, subsection: "focus_report" });
    return `story=${story.toUpperCase()}&${buildQueryString(constraints)}`;
  }

  return query;
};

/**
 * Transfrom report constraints - product and context arrays to objects - for logging
 */
export const transformReportConstraints = queryString => {
  const constraints = getConstraintsFromQuery(queryString) || {};
  const { story } = constraints;

  const arrayToObject = arr =>
    arr.reduce((acc, cur) => ({ ...acc, [cur.subsection]: cur.name }), {});

  if ([storyEnums.IDA, storyEnums.CGA, storyEnums.TREND].includes(story)) {
    const { product, context } = constraints;
    return {
      ...constraints,
      product: arrayToObject(product),
      context: arrayToObject(context)
    };
  }
  if (story === storyEnums.BB) {
    const { productA, productB, context } = constraints;
    return {
      ...constraints,
      productA: productA ? { [productA.subsection]: productA.name } : {},
      productB: productB ? { [productB.subsection]: productB.name } : {},
      context: arrayToObject(context)
    };
  }
  return constraints;
};

export const getFormattedAutoRangingConstraints = ({
  autoRangingConstraints,
  client
}) => {
  const {
    basicConstraints: {
      story,
      retailer,
      comparisonRetailer,
      period,
      consumptionType,
      categories,
      nDist
    },
    bayPlanConstraints,
    currentRangeConstraints: { inRange }
  } = autoRangingConstraints;

  const rangeSizesTableData = bayPlanConstraints.map(
    ({ rangeName, skus, newRangeSize, storesAccountedFor }) => ({
      range_name: rangeName,
      number_of_skus: skus,
      new_range_size: newRangeSize,
      percentage_of_stores_accounted_for: storesAccountedFor
    })
  );

  const currentRangeTableData = inRange.map(
    ({
      tag,
      sales,
      distribution,
      bayId,
      bayName,
      sku,
      salesFormatted,
      distributionFormatted
    }) => ({
      tag,
      sales,
      distribution,
      bay_id: bayId,
      bay_name: bayName,
      sku,
      sales_formatted: salesFormatted,
      distribution_formatted: distributionFormatted
    })
  );

  let scope = {};
  Object.values(categories).forEach(({ key, value }) => {
    scope = { ...scope, [key]: value };
  });

  const constraints = {
    retailer,
    comparison_retailer: comparisonRetailer,
    scope: { ...scope, consumption_type: consumptionType },
    range_sizes_table: rangeSizesTableData,
    current_range_table: currentRangeTableData,
    num_bays: bayPlanConstraints.length,
    story: story.toUpperCase(),
    period,
    client,
    ndist_threshold: nDist
  };

  return constraints;
};

export const getProductAttributes = (tag, tag2attribute) => {
  const attributes = tag2attribute[tag] || {};
  return {
    Category: attributes.category,
    Sector: attributes.sector,
    Distributor: attributes.distributor_id,
    "Major Brand": attributes.major_brand,
    "Sub Brand": attributes.sub_brand,
    Flavour: attributes.flavour,
    "Pack Type": attributes.pack_type,
    "Offer Type": attributes.offer_type,
    "Consumption Type": attributes.consumption_type,
    "Units Per Pack": attributes.units_per_package,
    "Container Material": attributes.container_material_grouped,
    "Volume Per Unit": attributes.pack_size_litres,
    "Sugar (gr/100mL)": attributes.sugar_gr_100mL,
    Calories: attributes.calories_kcal_100mL,
    "Weight of Virgin Plastic (gr)": attributes.virgin_plastic_weight_gr,
    "Pack Total Plastic Weight (gr)": attributes.pack_total_plastic_weight_gr,
    "rPET Percentage": attributes.rPET_percentage,
    "rPET Weight (gr)": attributes.rPET_weight_gr,
    "Pieces of Plastic": attributes.number_of_pieces_of_plastic,
    Recyclability: attributes.recyclability,
    "Carbon Emissions": attributes.carbon_emissions
  };
};

import React from "react";
import PropTypes from "prop-types";
import { formatNumber, wrapText } from "utils";
import PlotlyChart from "components/PlotlyChart";
import theme from "theme";

const SubChart = props => {
  const { data, display, colors } = props;
  const chartInfo = data.data.map((i, key) => ({ key: key + 1, type: i.type }));
  const numValues = data.data[0].x.length;
  const isSmall = numValues > 6;
  const isSubtitle = display.display.filter(i => i.title).length > 0;
  const isFirstClusteredBar = chartInfo[0].type === "clustered_bar";
  const { isLegend } = display;
  const axis = {
    yaxis: isFirstClusteredBar
      ? {
          type: "category",
          tickfont: { size: isSmall && 10 },
          automargin: true,
          tickcolor: "transparent",
          ticklen: 2,
          showgrid: false,
          fixedrange: true
        }
      : { showticklabels: false, showgrid: false, fixedrange: true },
    xaxis: isFirstClusteredBar
      ? { showticklabels: false, showgrid: false, fixedrange: true }
      : { type: "category", automargin: true, fixedrange: true }
  };
  if (chartInfo.length > 1) {
    for (let i = 1; i < chartInfo.length; i += 1) {
      if (chartInfo[i].type === "clustered_bar") {
        axis[`xaxis${i + 1}`] = {
          showticklabels: false,
          showgrid: false,
          zeroline: false,
          fixedrange: true
        };
      }
      if (chartInfo[i].type === "clustered_column") {
        axis[`xaxis${i + 1}`] = {
          type: "category",
          automargin: true,
          fixedrange: true
        };
      }
    }
  }
  return (
    <PlotlyChart
      data={data.data.map((chart, key) => {
        const isValence = display.display[key]?.apply?.includes("valence");
        const numberFormat = display.display[key]?.labels_style?.number_format;
        return {
          x:
            chart.type === "clustered_bar"
              ? chart.y
              : chart.x.map(i => wrapText(i, 10)),
          y: chart.type === "clustered_bar" ? chart.x : chart.y,
          name: chart.name,
          type: "bar",
          orientation: chart.type === "clustered_bar" && "h",
          text: chart.y.map(i => formatNumber(i, numberFormat)),
          textposition: isValence && "outside",
          textfont: {
            color:
              isValence && chart.y.map(i => (i > 0 ? "#01B050" : "#FF0000")),
            size: isValence && isSmall ? 10 : 12
          },
          insidetextfont: { color: "#FFFFFF" },
          cliponaxis: false,
          marker: {
            color:
              (isValence &&
                chart.y.map(i => (i > 0 ? "#01B050" : "#FF0000"))) ||
              colors[key]
          },
          xaxis: `x${key + 1}`,
          yaxis: "y"
        };
      })}
      layout={{
        ...axis,
        annotations:
          isSubtitle &&
          display.display.map((i, key) => ({
            text: wrapText(i.title, 50),
            showarrow: false,
            font: { size: 12, color: theme.palette.grey[500] },
            xref: `x${key + 1} domain`,
            xanchor: "center",
            x: 0.5,
            yref: "y domain",
            yanchor: "top",
            y: -0.05
          })),
        margin: { t: 20, b: isSubtitle ? 60 : 40, r: 10, l: 10 },
        showlegend: isLegend,
        legend: {
          orientation: "h",
          xanchor: "center",
          y: -0.2,
          x: 0.5,
          font: { size: 10 }
        },
        grid: {
          rows: 1,
          columns: chartInfo.length,
          subplots: chartInfo.map(i =>
            i.type === "clustered_bar" ? `xy${i.key}` : `x${i.key}y`
          )
        }
      }}
    />
  );
};

SubChart.propTypes = {
  data: PropTypes.shape(),
  display: PropTypes.shape(),
  colors: PropTypes.arrayOf(PropTypes.string)
};

SubChart.defaultProps = {
  data: {},
  display: {},
  colors: []
};

export default SubChart;
